import React, { useContext } from "react";
import styled from "styled-components";
import { selectLanguage } from "assets/Common/ContextAPI";
import { MAIN_PRODUCT_LIST } from "assets/Common/RenderData/Main";

const FootBottomRight = () => {
  const language = useContext(selectLanguage);

  //새 페이지를 기존페이지 내에서 열기 위한 함수입니다.
  const redirectInPage = e => {
    const url = e.target.value;
    if (url) {
      document.location.href = url;
    }
  };

  return (
    <FootBottomRightContainerDiv>
      <FootBottomRightContentsSelect onChange={redirectInPage}>
        <option>Family Site</option>
        {Object.keys(MAIN_PRODUCT_LIST).map(site => (
          <option key={site} value={MAIN_PRODUCT_LIST[site][language].url}>
            {MAIN_PRODUCT_LIST[site][language].name}
          </option>
        ))}
      </FootBottomRightContentsSelect>
    </FootBottomRightContainerDiv>
  );
};

const FootBottomRightContentsSelect = styled.select`
  float: right;
  width: 200px;
  padding: 0.8em 0.5em;
  background: #252525;
  color: #848484;
  border: 1px solid #999;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const FootBottomRightContainerDiv = styled.div`
  position: relative;
  right: 20vw;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    position: relative;
    bottom: 2vw;
  }
`;
export default FootBottomRight;
