import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

const rootElement = document.getElementById("root");

const renderApp = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

render(renderApp, rootElement);
