import React, { useContext } from 'react';
import { LANDING_TITLE, DEVICE_TITLE, NEXT_TITLE, NEXT_LIST} from '../../assets/Common/RenderData/Landing';
import { selectLanguage } from "assets/Common/ContextAPI";
import styled from 'styled-components';
import LandingImg from '../../assets/imgs/BRIDGE_LANDING/landingImg.png';


const Landing = () => {
  const language = useContext(selectLanguage);
  
  return (
    <LandingContainer>
      <TitleSection>
        <span>{LANDING_TITLE.title[language]}</span>
      </TitleSection>
      <ImgSection>
        <img src={LandingImg}/>
        <p>{LANDING_TITLE.subTitle[language]}</p>
      </ImgSection>
      <IOSsection>
        <IosTitle>{DEVICE_TITLE.IOStitle[language]}</IosTitle>
        <a href='https://apps.apple.com/us/app/cpr-add-on-kit-student/id1400115591' target='_blank'>
          <IosLinkButton>
            <h3>apps.apple.com</h3>
            <h4>https://apps.apple.com/us/app/cpr-add-on-kit-student</h4>
          </IosLinkButton>
        </a>
      </IOSsection>
      <ANDsection>
        <AndTitle>{DEVICE_TITLE.ANDtitle[language]}</AndTitle>
        <a href='https://play.google.com/store/apps/details?id=heartisense_student.android.imlabworld.com.heartisensestudent' target='_blank'>
          <AndLinkButton>
            <h3>CPR add-on kit Student - Apps on Google Play</h3>
            <h4>https://play.google.com/store/apps/details?id=heartisense_student</h4>
          </AndLinkButton>
        </a>
      </ANDsection>
      <DescriptSection>
        <DescriptTitle>{NEXT_TITLE.title[language]}</DescriptTitle>
        <DescriptDetail>
          <li>{NEXT_LIST.firstList[language]}</li>
          <li>{NEXT_LIST.secondList[language]}</li>
          <li>{NEXT_LIST.thirdList[language]}</li>
        </DescriptDetail>
      </DescriptSection>
    </LandingContainer>
  );
};

export default Landing;

const LandingContainer = styled.section`
  font-family: MontserratRegular, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80vw;
  max-width: 800px;
  margin: 0 auto;
  padding: 100px 0 0 0;
  overflow-y: hidden;
`;

const TitleSection = styled.div`
  padding-bottom: 70px;
  span {
    color: rgb(55, 53, 47);
    font-size: 46px;
    font-weight: 900;
      @media (max-width: 720px) {
      font-size: 26px;
    }
  }
`;

const ImgSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  p {
    color: rgb(55, 53, 47);
    font-size: 1.5em;
    font-weight: 800;

    @media (max-width: 720px) {
      font-size: 16px;
      padding: 0 20px 0 20px;
    }

      span {
        color: #EB5757;
        background-color: rgba(135,131,120,0.15);
      }
  }
  img {
      width: 100%;
      height: auto;
    }
`;

const IOSsection = styled.div`
  width: 100%;
  padding-top: 60px;
`;

const IosTitle = styled.span`
  color: rgb(55, 53, 47);
  font-size: 32px;
  font-weight: 700;

  @media (max-width: 720px) {
    font-size: 26px;
  }
`;

const IosLinkButton = styled.button`
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgba(55, 53, 47, 0.16);
  background-color: transparent;
  cursor: pointer;
  text-align: left;
  padding: 0 20px 0 20px;
  h3 {
    font-size: 18px;
    font-weight: 600;
    color: rgb(55, 53, 47);
  }
  h4 {
    font-size: 15px;
    font-weight: 500;
    color: rgb(55, 53, 47);
  }
  &:hover {
    background-color: #f5f5f5;
  }
`;

const ANDsection = styled(IOSsection)``;

const AndTitle = styled(IosTitle)``;

const AndLinkButton = styled(IosLinkButton)``;

const DescriptSection = styled.section`
  width: 100%;
  padding-top: 60px;
`;

const DescriptTitle = styled.span`
  color: rgb(55, 53, 47);
  font-size: 32px;
  font-weight: 700;
  background-color: rgb(250,235,221);

  @media (max-width: 720px) {
    font-size: 26px;
  }
`;

const DescriptDetail = styled.ol`
  color: rgb(55, 53, 47);
  font-size: 21px;
  li {
    padding-top: 20px;

    @media (max-width: 720px) {
      font-size: 18px;
    }
  }
`;