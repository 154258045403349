import React from "react";
import styled from "styled-components";
import { DotLessUl } from "assets/Common/CSS";
import { NavLink } from "react-router-dom";

const FootTopLeft = ({ language, data }) => {
  return (
    <FootTopLeftLeftContainerDiv>
      <DotLessUl>
        {data.map(menu => {
          const snnipet = menu[language];
          return (
            <FootTopLeftWrapperLi key={menu.name}>
              <FootTopLeftContentNavLink to={snnipet.url}>
                {snnipet.name}
              </FootTopLeftContentNavLink>
            </FootTopLeftWrapperLi>
          );
        })}
      </DotLessUl>
    </FootTopLeftLeftContainerDiv>
  );
};

//Styled. CSS
const FootTopLeftLeftContainerDiv = styled.div`
  text-align: center;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    position: relative;
    right: 3.5vw;
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const FootTopLeftWrapperLi = styled.li`
  position: relative;
  top: -1vh;
  left: 10vw;
  float: left;
  margin-left: 3.5vw;
`;

const FootTopLeftContentNavLink = styled(NavLink)`
  position: relative;
  right: 1.1vw;
  top: 0.8vh;
  color: #848484;
  text-decoration: none;
  margin: 0;

  &:hover {
    color: #0066cc;
  }

  &:visited {
    text-decoration: none;
  }
`;

export default FootTopLeft;
