import React from "react";
import styled from "styled-components";

const DetailContactInfo = ({ contactInfo }) => {
  const Infos = Object.keys(contactInfo)
    .slice(0, -1)
    .reverse();
  return (
    <div>
      <DetailContactInfoAddressP>
        {contactInfo.address}
      </DetailContactInfoAddressP>
      {Infos.map(Info => (
        <DetailContactInfoContentP
          key={Info}
        >{`${Info}. ${contactInfo[Info]}`}</DetailContactInfoContentP>
      ))}
    </div>
  );
};

//Styled CSS
const DetailContactInfoAddressP = styled.p`
  font-weight: bold;
  font-size: 20px;
  color: #5d5d5d;
  margin-top: 28px;
  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 2.8vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 3.5vw;
  }
`;

const DetailContactInfoContentP = styled.p`
  color: #5d5d5d;
  font-size: 18px;
  margin-top: 28px;
  @media screen and (max-width: 1600px) {
    font-size: 15px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 2vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 3vw;
    margin-top: 3vw;
  }
`;

export default DetailContactInfo;
