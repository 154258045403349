import React, { useState } from 'react';
import styled from 'styled-components';
import { DotLessUl } from 'assets/Common/CSS';

const HeadLanguageSelect = ({ subscribeLanguage, visibleStatus }) => {
  // eslint-disable-next-line no-unused-vars
  const [languages, setLanguages] = useState(['KOR', 'ENG']);
  return (
    <DotLessUl>
      {languages.map(language => (
        <HeadLanguageSelectWrapperdLi
          key={language}
          visibleStatus={visibleStatus}
        >
          <HeadLanguageSelectContentP
            language
            onClick={subscribeLanguage.bind(null, language)}
          >
            {language}
          </HeadLanguageSelectContentP>
        </HeadLanguageSelectWrapperdLi>
      ))}
    </DotLessUl>
  );
};

const HeadLanguageSelectWrapperdLi = styled.li`
  position: relative;
  top: 1vw;
  margin: 0.5vw;
  float: left;
`;

const HeadLanguageSelectContentP = styled.p`
  font-family: MontserratRegular;
  font-size: ${props => (props.language ? '0.9rem' : '1rem')};
  cursor: pointer;
  margin: 0.1rem;
  position: relative;
  top: ${props => (props.language ? '0.15rem' : 'none')};
  &:hover {
    color: #0066cc;
  }
`;
//styled CSS
export default HeadLanguageSelect;
