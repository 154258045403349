import React from "react";
import styled from "styled-components";
import RecruitBottomContent from "./RecruitBottomContent";
import {
  RECRUIT_BOTTOM_CONTENTS,
  RECRUIT_BOTTOM_TITLE
} from "assets/Common/RenderData/Recruit";

const RecruitBottom = ({ language }) => {
  const titles = Object.keys(RECRUIT_BOTTOM_TITLE);
  return (
    <RecruitBottomAllContainerDiv>
      {/* {titles.map(title => {
        const maintitle = RECRUIT_BOTTOM_TITLE[title][language];
        const contentList = RECRUIT_BOTTOM_CONTENTS[title][language];
        return (
          <RecruitBottomContent
            key={title}
            title={maintitle}
            contentList={contentList}
          />
        );
      })} */}
    </RecruitBottomAllContainerDiv>
  );
};

const RecruitBottomAllContainerDiv = styled.div`
  letter-spacing: 0.7px;
  font-family: NanumGothic;
  @media screen and (max-width: 700px) {
    padding-bottom: 10vw;
  }
`;

export default RecruitBottom;
