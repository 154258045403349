export const INTRO_TITLE = {
  title: {
    KOR: 'I.M.LAB',
    ENG: 'I.M.LAB',
  },
  subTitle: {
    KOR: 'INTERACTIVE MEDIA LAB',
    ENG: 'INTERACTIVE MEDIA LAB',
  },
};

export const INTRO_PAGE = {
  about: {
    KOR: {
      name: '회사소개',
      url: '/company/about-us',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/home%2Fintro_company.png?alt=media&token=f3c74488-7a96-4b94-b198-939abbb1f8a8',
      description:
        'I.M.LAB(interactive media lab)에는 다양한 분야의 전문가들이 모여 사람을 이롭게 하는 기술을 개발하고 있습니다.',
    },
    ENG: {
      name: 'ABOUT US',
      url: '/company/about-us',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/home%2Fintro_company.png?alt=media&token=f3c74488-7a96-4b94-b198-939abbb1f8a8',
      description:
        'I.M.LAB (interactive media lab) gathers experts from various fields to develop technologies that benefit people.',
    },
  },
  service: {
    KOR: {
      name: '서비스',
      url: '/company/services',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/home%2Fintro_product.png?alt=media&token=ccc467c8-128e-4f42-9e1a-61bc0bb3f8dc',
      description:
        '보다 많은 생명을 구하는데 도움을 주기 위해 헬스케어 관련 새로운 제품을 개발하고 있습니다.',
    },
    ENG: {
      name: 'SERVICES',
      url: '/company/services',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/home%2Fintro_product.png?alt=media&token=ccc467c8-128e-4f42-9e1a-61bc0bb3f8dc',
      description:
        'We are developing new products for healthcare to help save even more lives.',
    },
  },
};

export const PARTNERS_TITLE = {
  KOR: 'PARTNERS',
  ENG: 'PARTNERS',
};

export const PARTNERS_CONTENTS = {
  3: {
    name: 'Nasco',
    image:
      'https://imlab-contents.s3-ap-northeast-1.amazonaws.com/imlabworld/%ED%98%91%EB%A0%A5%EC%82%AClogo/logosNasco_200304.png',
  },
  1: {
    name: '3B',
    image:
      'https://imlab-contents.s3-ap-northeast-1.amazonaws.com/imlabworld/%ED%98%91%EB%A0%A5%EC%82%AClogo/logos3B_200304.png',
  },
  4: {
    name: 'Presten',
    image:
      'https://imlab-contents.s3-ap-northeast-1.amazonaws.com/imlabworld/%ED%98%91%EB%A0%A5%EC%82%AClogo/logosPrestan_200304.png',
  },
  2: {
    name: 'CAE',
    image:
      'https://imlab-contents.s3-ap-northeast-1.amazonaws.com/imlabworld/%ED%98%91%EB%A0%A5%EC%82%AClogo/logosCAE_200304.png',
  },
  9: {
    name: 'Redcross',
    image:
      'https://imlab-contents.s3-ap-northeast-1.amazonaws.com/imlabworld/%ED%98%91%EB%A0%A5%EC%82%AClogo/logosRedcross_200304.png',
  },
  10: {
    name: 'nediana',
    image:
      'https://imlab-contents.s3-ap-northeast-1.amazonaws.com/imlabworld/%ED%98%91%EB%A0%A5%EC%82%AClogo/logosnediana_200304.png',
  },
  11: {
    name: 'bestcpr',
    image:
      'https://imlab-contents.s3-ap-northeast-1.amazonaws.com/imlabworld/%ED%98%91%EB%A0%A5%EC%82%AClogo/logosbestcpr_200304.png',
  },
  7: {
    name: '119safty',
    image:
      'https://imlab-contents.s3-ap-northeast-1.amazonaws.com/imlabworld/%ED%98%91%EB%A0%A5%EC%82%AClogo/logos119safty_200304.png',
  },
  12: {
    name: 'samsung',
    image:
      'https://imlab-contents.s3-ap-northeast-1.amazonaws.com/imlabworld/%ED%98%91%EB%A0%A5%EC%82%AClogo/logossamsung_200304.png',
  },
  8: {
    name: 'KACPR',
    image:
      'https://imlab-contents.s3-ap-northeast-1.amazonaws.com/imlabworld/%ED%98%91%EB%A0%A5%EC%82%AClogo/logosKACPR_200304.png',
  },
  13: {
    name: 'emedic',
    image:
      'https://imlab-contents.s3-ap-northeast-1.amazonaws.com/imlabworld/%ED%98%91%EB%A0%A5%EC%82%AClogo/logosemedic_200304.png',
  },
  14: {
    name: 'Mind & Manual',
    image:
      'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/home%2Fmind%20%26%20manual.png?alt=media&token=f5e4228d-f48c-4e28-af98-4413273bffea',
  },
  5: {
    name: 'sofrapa',
    image:
      'https://imlab-contents.s3-ap-northeast-1.amazonaws.com/imlabworld/%ED%98%91%EB%A0%A5%EC%82%AClogo/logossofrapa_200304.png',
  },
  6: {
    name: 'Trucorp',
    image:
      'https://imlab-contents.s3-ap-northeast-1.amazonaws.com/imlabworld/%ED%98%91%EB%A0%A5%EC%82%AClogo/logosTrucorp_200304.png',
  },
};
