import React, { useState } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";

const IntroContent = ({ title, image, url, description, language }) => {
  const [redirectStatus, setRedirectStatus] = useState(false);
  const setRedirect = () => {
    setRedirectStatus(!redirectStatus);
    if (redirectStatus) {
      return <Redirect to={url} />;
    }
  };

  const renderRedirect = () => {
    if (redirectStatus) {
      return <Redirect to={url} />;
    }
  };

  return (
    <IntroContentContainerDiv>
      <IntroContentTitleH3>{title}</IntroContentTitleH3>
      {renderRedirect()}
      {url ? (
        <IntroContentLinkP onClick={setRedirect}>
          {language === "KOR" ? "더보기>" : "MORE>"}
        </IntroContentLinkP>
      ) : null}

      <IntroContentImg src={image} alt="" />
      <IntroContentDescriptionP>{description}</IntroContentDescriptionP>
    </IntroContentContainerDiv>
  );
};

const IntroContentContainerDiv = styled.div`
  /*border : 1px solid red;*/
  width: 100%;
`;
const IntroContentTitleH3 = styled.h3`
  /*border: 1px solid black;*/
  position: relative;
  text-align: left;
  top: 3vw;
  right: 10vw;
  padding: 0;
  margin: 0 14vw 2vw 15vw;
  font-size: 30px;
  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
  @media screen and (max-width: 1024px) {
    text-align: center;
    right: 2vw;
    font-size: 4vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 4.5vw;
  }
`;

const IntroContentImg = styled.img`
  width: 80%;
  @media screen and (max-width: 1024px) {
    width: 90%;
  }
`;

const IntroContentLinkP = styled.p`
  cursor: pointer;
  position: relative;
  left: 15.5vw;
  font-size: 18px;
  color: #0066cc;
  @media screen and (max-width: 1600px) {
    font-size: 15px;
  }
  @media screen and (max-width: 1024px) {
    left: 38vw;
    font-size: 2.5vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 3vw;
  }
`;

const IntroContentDescriptionP = styled.p`
  width: 80%;
  margin: auto;
  line-height: 33px;
  text-align: left;
  word-break: keep-all;
  font-size: 25px;
  @media screen and (max-width: 1600px) {
    word-break: break-all;
    font-size: 18px;
  }
  @media screen and (max-width: 1024px) {
    width: 90%;
    line-height: 4vw;
    font-size: 2.5vw;
  }
  @media screen and (max-width: 600px) {
    line-height: 6vw;
    font-size: 3.5vw;
  }
`;
export default IntroContent;
