import React, { useContext, useState } from "react";
import styled from "styled-components";
import HeadNav from "./Components/HeadNav/HeadNav";
import Header from "./Components/Header/Header";
import RoutePages from "./RoutePages";
import Footer from "./Components/Footer/Footer";
import { selectPage } from "./assets/Common/ContextAPI";
import MobileMenu from "./Components/MobileMenu/MobileMenu";

const Screen = ({ subscribeLanguage, subscribePage }) => {
  const page = useContext(selectPage);
  const [menuVisibleStatus, setMenuVisibleStatus] = useState();
  const subscribeMenuStatus = status => {
    setMenuVisibleStatus(status);
  };

  return (
    <BodyContainerDiv>
      <HeadNav
        subscribeLanguage={subscribeLanguage}
        subscribeMenuStatus={subscribeMenuStatus}
        menuVisibleStatus={menuVisibleStatus}
      />
      <MobileMenu
        subscribeLanguage={subscribeLanguage}
        subscribeMenuStatus={subscribeMenuStatus}
        menuVisibleStatus={menuVisibleStatus}
      />

      {page === "main" || page === "not-found" ? null : <Header />}
      <PagesContainerDiv page={page}>
        <RoutePages subscribePage={subscribePage} page={page} />
      </PagesContainerDiv>

      {page === "main" ? null : <Footer />}
    </BodyContainerDiv>
  );
};

//Styled CSS
const BodyContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const PagesContainerDiv = styled.div`
  padding-bottom: ${props => (props.page === "about-us" ? 0 : "100px")};
  @media screen and (max-width: 1024px) {
    padding-bottom: ${props => (props.page === "about-us" ? 0 : "10vw")};
  }
`;

export default Screen;
