import React, { useContext } from "react";
import styled from "styled-components";
import { newLineMaker } from "assets/Common/Functions";
import { selectLanguage } from "assets/Common/ContextAPI";
import { ABOUT_TITLE } from "assets/Common/RenderData/About";

const ValueTitle = () => {
  const language = useContext(selectLanguage);
  return (
    <>
      <ValueTitleWrapperDiv>
        <ValueTitleH1>{ABOUT_TITLE.title[language].name}</ValueTitleH1>
        <ValueTitleDivideHr />
      </ValueTitleWrapperDiv>
      <ValueTitleDescriptionDiv>
        {newLineMaker(ABOUT_TITLE.title[language].description)}
      </ValueTitleDescriptionDiv>
    </>
  );
};

//Styled CSS
const ValueTitleWrapperDiv = styled.div`
  display: inline-block;
  text-align: center;
`;

const ValueTitleH1 = styled.p`
  font-size: 40px;
  color: #5d5d5d;
  word-break: keep-all;
  @media screen and (max-width: 1600px) {
    font-size: 34px;
  }
  @media screen and (max-width: 1024px) {
    width: 80%;
    margin: auto;
    line-height: 8vw;
    font-size: 3.8vw;
  }
  @media screen and (max-width: 600px) {
    padding: auto 0 auto 0;
    width: 100%;
    font-size: 5.3vw;
    line-height: 10vw;
    font-weight: bold;
  }
`;

const ValueTitleDescriptionDiv = styled.div`
  font-size: 25px;
  line-height: 3vw;
  color: #0066cc;
  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
  @media screen and (max-width: 1024px) {
    width: 95%;
    margin: auto;
    text-align: center;
    font-size: 2.5vw;
    line-height: 5vw;
    word-break: keep-all;
  }
  @media screen and (max-width: 600px) {
    font-size: 4vw;
    width: 83%;
    line-height: 7vw;
  }
`;

const ValueTitleDivideHr = styled.hr`
  width: 100%;
  margin: 30px auto 30px auto;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export default ValueTitle;
