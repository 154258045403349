import React from "react";
import styled from "styled-components";
import FootBottomLeft from "./FootBottomLeft";
import FootBottomRight from "./FootBottomRight";

const FootBottom = () => (
  <FootBottomContainerDiv>
    <FootBottomLeft />
    <FootBottomRight />
  </FootBottomContainerDiv>
);

const FootBottomContainerDiv = styled.div`
  background-color: #1c1c1c;
  padding: 0.9vw;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 100%;
    text-align: center;
  }
`;

export default FootBottom;
