export const LANDING_TITLE = {
  title: {
    KOR: 'CPR Add-on Kit Remote 세션 참여를 환영합니다!',
    ENG: 'Welcome to CPR Add-on Kit Remote'
  },
  subTitle: {
    KOR: '리모트 세션에 참여하기 위해서는 모바일 기기에 CPR Add-on Kit Student 앱을 다운로드 받아야 합니다.',
    ENG: 'To join the remote training session, you have to get the CPR Add-on Kit Student app on your mobile device.'
  }
};

export const DEVICE_TITLE = {
  IOStitle: {
    KOR: 'iOS 기기',
    ENG: 'iOS Device' 
  },
  ANDtitle: {
    KOR: 'Android 기기',
    ENG: 'Android Device' 
  }
}

export const NEXT_TITLE = {
  title: {
    KOR: '다음 스텝은 무엇인가요?',
    ENG: "What's the next step?"
  }
}

export const NEXT_LIST = {
  firstList: {
    KOR: '참여할 세션의 일정(시간, 날짜)를 확인하세요.',
    ENG: 'Check the date and time of the session in which you will join.'
  },
  secondList: {
    KOR: 'QR코드를 스캔하거나 링크를 다시 여세요.',
    ENG: 'Scan your QR code or join the link.'
  },
  thirdList: {
    KOR: '표시되는 지시에 따라서 진행해주세요.',
    ENG: 'Follow the instructions.'
  }
}