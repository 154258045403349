// 이곳은 공통으로 사용되는 CSS 파일이 저장되는 곳입니다.
import styled from 'styled-components';

const DotLessUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export { DotLessUl };
