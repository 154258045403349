import React from "react";
import styled from "styled-components";
import ValueTitle from "./ValueTitle";
import ValueContentList from "./ValueContentList";

const Value = () => {
  return (
    <ValueContainerDiv>
      <ValueTitle />
      <ValueContentList />
    </ValueContainerDiv>
  );
};

const ValueContainerDiv = styled.div`
  /*border: 1px solid red;*/
  font-family: NanumGothic, sans-serif;
  text-align: center;
  width: 90%;
  margin: 5vw auto 0 auto;
  @media screen and (max-width: 1130px) {
    margin-top: 8vw;
  }
`;

export default Value;
