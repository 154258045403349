import React from 'react';
import styled from 'styled-components';

const AdviserContent = ({ name, description, school }) => {
  return (
    <>
      <AdviserContentNameP>{name}</AdviserContentNameP>
      {[description, school].map((curr) => (
        <AdviserContentDetailInfoP key={curr}>{curr}</AdviserContentDetailInfoP>
      ))}
    </>
  );
};

const AdviserContentNameP = styled.p`
  font-size: 20px;
  font-weight: 650;
  letter-spacing: 0.7px;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    font-size: 5vw;
  }
`;

const AdviserContentDetailInfoP = styled.p`
  font-family: OpenSans, sans-serif;
  font-weight: 400;
  font-size: 17px;
  color: gray;
  letter-spacing: 0.7px;
  line-height: 25px;
  margin: 0;
  width: 100%;
  @media screen and (max-width: 600px) {
    line-height: 5vw;
    font-size: 4.5vw;
  }
`;

export default AdviserContent;
