import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { selectLanguage } from 'assets/Common/ContextAPI';
import ProductList from './Product/ProductList';
import PopupImg from '../../assets/imgs/imlab_popup.png';
import PopupCloseButton from '../../assets/imgs/popup_close_button.webp';
import PopupTitle from '../../assets/imgs/imlab_popup_title.webp';
import PopupMainImg from '../../assets/imgs/imlab_popup_main_image.webp';
import PopupLink from '../../assets/imgs/popup_link_button.webp';

const Main = ({ subscribePage, location }) => {
  subscribePage(location);
  const language = useContext(selectLanguage);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const user = navigator.userAgent;

    if (user.indexOf('iPhone') > -1 || user.indexOf('Android') > -1) {
      setIsMobile(true);
    }
  }, [navigator.userAgent]);

  const [isPopupOpened, setIsPopupOpened] = useState(true);

  return (
    <ProductContainerDiv>
      {isPopupOpened && (
        <PopupContainer>
          {isMobile ? (
            <>
              <MobilePopupTitleContainer>
                <img
                  className='popupTitle'
                  src={PopupTitle}
                  alt='popup title'
                />
                <img
                  className='closeBtn'
                  src={PopupCloseButton}
                  alt='popup close button'
                  onClick={() => {
                    setIsPopupOpened(false);
                  }}
                />
              </MobilePopupTitleContainer>
              <MobilePopupMainImageContainer>
                <img
                  className='popup_main_img'
                  src={PopupMainImg}
                  alt='popup main image'
                />
              </MobilePopupMainImageContainer>
              <MobilePopupLinkContainer>
                <img src={PopupLink} alt='popup link' />
                <a
                  className='proposal'
                  href='https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/pdf%2FI.M.LAB_202401_%E1%84%8B%E1%85%A1%E1%86%AB%E1%84%8C%E1%85%A5%E1%86%AB%E1%84%80%E1%85%AD%E1%84%8B%E1%85%B2%E1%86%A8%E1%84%8C%E1%85%A6%E1%84%8B%E1%85%A1%E1%86%AB%E1%84%89%E1%85%A5_public.pdf?alt=media&token=dcd7bd4c-e093-493b-b6fd-cd0ab4bf0251'
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={() => {
                    setIsPopupOpened(false);
                  }}
                ></a>
                <a
                  className='contact'
                  href='https://imlabworld.com/company/contact-us/'
                  onClick={() => {
                    setIsPopupOpened(false);
                  }}
                ></a>
              </MobilePopupLinkContainer>
            </>
          ) : (
            <PopupLinkContainer>
              <img
                className='closeBtn'
                src={PopupCloseButton}
                alt='popup close button'
                onClick={() => {
                  setIsPopupOpened(false);
                }}
              />
              <img className='popupImg' src={PopupImg} alt='imlabPopupImg' />
              <a
                className='proposal'
                href='https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/pdf%2FI.M.LAB_202401_%E1%84%8B%E1%85%A1%E1%86%AB%E1%84%8C%E1%85%A5%E1%86%AB%E1%84%80%E1%85%AD%E1%84%8B%E1%85%B2%E1%86%A8%E1%84%8C%E1%85%A6%E1%84%8B%E1%85%A1%E1%86%AB%E1%84%89%E1%85%A5_public.pdf?alt=media&token=dcd7bd4c-e093-493b-b6fd-cd0ab4bf0251'
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => {
                  setIsPopupOpened(false);
                }}
              ></a>
              <a
                className='contact'
                href='https://imlabworld.com/company/contact-us/'
                onClick={() => {
                  setIsPopupOpened(false);
                }}
              ></a>
            </PopupLinkContainer>
          )}
        </PopupContainer>
      )}
      <ProductList language={language} />
    </ProductContainerDiv>
  );
};

const ProductContainerDiv = styled.div`
  position: absolute;
  width: 100%;
  top: 20%;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    top: 25%;
  }
  @media screen and (max-width: 600px) {
    top: 20%;
  }
`;

const PopupContainer = styled.div`
  width: 35vw;
  position: fixed;
  top: 125px;
  left: 75px;
  z-index: 301;

  @media screen and (max-width: 1024px) {
    background-color: #ffffff;
    width: 90vw;
    height: calc(80vh - 100px);
    top: 100px;
    left: 5vw;
  }
`;

const MobilePopupTitleContainer = styled.div`
  img.popupTitle {
    width: 100%;
    height: auto;
  }

  img.closeBtn {
    position: absolute;
    cursor: pointer;

    top: 5px;
    left: 5px;

    width: 2em;
    height: 2em;
  }
`;

const MobilePopupMainImageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 45%;

  img.popup_main_img {
    width: auto;
    height: 90%;
  }
`;

const MobilePopupLinkContainer = styled.div`
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  a {
    position: absolute;
    bottom: 23%;
    display: block;
    width: 40%;
    height: 23%;
    cursor: pointer;
  }

  a.proposal {
    left: 5%;
  }

  a.contact {
    right: 5%;
  }
`;

const PopupLinkContainer = styled.div`
  position: relative;

  img.closeBtn {
    position: absolute;
    cursor: pointer;

    top: 1.5%;
    right: 1.5%;

    width: 7%;
    height: auto;
    background-color: #fff;
  }

  img.popupImg {
    width: 100%;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  }

  a {
    position: absolute;
    bottom: 7.5%;
    display: block;
    width: 34%;
    height: 8%;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
      width: 47.5%;
      height: 9%;
      bottom: 5.5%;
    }
  }

  a.proposal {
    left: 15%;

    @media screen and (max-width: 1024px) {
      left: 1%;
    }
  }

  a.contact {
    right: 15%;

    @media screen and (max-width: 1024px) {
      right: 2.5%;
    }
  }
`;

export default Main;
