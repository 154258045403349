import React, { useContext } from "react";
import styled from "styled-components";
import ValueContent from "./ValueContent";
import { selectLanguage } from "assets/Common/ContextAPI";
import { ABOUT_VALUE, ABOUT_VALUE_TITLE } from "assets/Common/RenderData/About";

const ValueContentList = () => {
  const language = useContext(selectLanguage);
  const values = Object.keys(ABOUT_VALUE);
  return (
    <>
      <ValueContentTitleWrapperDiv>
        <ValueContentListTitleP>
          {ABOUT_VALUE_TITLE.title[language]}
        </ValueContentListTitleP>
        <ValueContentListDivideHr />
      </ValueContentTitleWrapperDiv>
      <ValueContentListContainerDiv>
        {values.map((value, index) => {
          const snnipet = ABOUT_VALUE[value][language];
          return (
            <ValueContentListWrapperDiv key={snnipet.name}>
              <ValueContent
                name={`${index + 1}. ${snnipet.name}`}
                image={snnipet.image}
                description={snnipet.description}
              />
            </ValueContentListWrapperDiv>
          );
        })}
      </ValueContentListContainerDiv>
    </>
  );
};

//Styled CSS
const ValueContentListContainerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  margin: auto;

  @media screen and (max-width: 1600px) {
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    margin: 3vw auto 3vw auto;
  }
`;

const ValueContentTitleWrapperDiv = styled.div`
  display: inline-block;
`;

const ValueContentListWrapperDiv = styled.div`
  width: 25%;
  @media screen and (max-width: 1024px) {
    width: 40vw;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const ValueContentListTitleP = styled.p`
  font-family: MontserratRegular, sans-serif;
  word-break: keep-all;
  font-size: 2vw;
  color: #0066cc;
  @media screen and (max-width: 1600px) {
    font-size: 3vw;
  }
  @media screen and (max-width: 1024px) {
    font-size: 4vw;
  }
  @media screen and (max-width: 600px) {
    margin: auto 0 10vw 0;
    font-size: 6vw;
  }
`;

const ValueContentListDivideHr = styled.hr`
  width: 100%;
  margin: 3vw auto 3vw auto;
`;

export default ValueContentList;
