import React from "react";
import styled from "styled-components";

const Product = ({ image, logo, description, name }) => {
  return (
    <ProductImgDiv image={image}>
      <LogoImg src={logo} alt="" name={name} />
      <DescriptionP name={name}>{description}</DescriptionP>
    </ProductImgDiv>
  );
};

//Styled CSS

const DescriptionP = styled.p`
  position: absolute;
  left: 35px;
  font-size: 20px;
  bottom: 0;
  @media screen and (max-width: 1024px) {
    left: 20px;
    font-size: 18px;
  }
  @media screen and (max-width: 600px) {
    left: 10px;
    font-size: 3.3vw;
    bottom: -10vw;
  }
`;

const LogoImg = styled.img`
  position: absolute;
  width: 28%;
  left: 35px;
  bottom: 50px;
  @media screen and (max-width: 1024px) {
    left: 20px;
  }
  @media screen and (max-width: 600px) {
    width: 50%;
    left: 10px;
    bottom: 0;
  }
`;

const ProductImgDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background: url(${props => props.image});
    background-size: cover;
  }
  @media screen and (max-width: 1024px) {
    height: 28vw;
  }
`;

export default Product;
