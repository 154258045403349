//import { db } from '../../../firebase';

export const RECRUIT_TOP_TITLE = {
  title: {
    KOR: "WHO WE ARE LOOKING FOR",
    ENG: "WHO WE ARE LOOKING FOR"
  }
};
export const RECRUIT_TOP_CONTENTS = {
  A: {
    ENG: {
      description:
        "We are recruiting members who can share and respect each other’s opinions while growing rapidly with the organization for achieving common vision. We have a culture, that we briefly describe amd share our work for the day every morning. These meetings will help you plan your day's work and communicate with others for collaboration.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/recruit%2F4life.svg?alt=media&token=9ee949d1-71af-4c77-bf1d-1915806d842d",
      name:
        "Flexible mindset that can respect contrasting opinions and understand each other’s differences"
    },
    KOR: {
      description:
        "공통의 비전을 위해 서로의 의견을 공유하고 존중하며 조직과 함께 빠르게 성장해나갈 수 있는 멤버를 모집하고 있습니다. 저희는 아침마다 간단히 자신의 업무를 설명하여 공유하는 문화를 갖고 있습니다. 이 회의를 통하여 하루의 업무를 계획하고, 다른 조직원과의 협업에 필요한 의사소통을 하기도 합니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/recruit%2F4life.svg?alt=media&token=9ee949d1-71af-4c77-bf1d-1915806d842d",
      name:
        "서로의 차이를 이해하고 이견을 존중할 수 있는 유연한 사고를 지니신 분"
    }
  },
  B: {
    ENG: {
      description:
        "All members of I.M.LAB have a culture of continuously learning and being always engaged with self development, to maintain being experts in their fields. That's why we are willing to share our current experiences and knowledge, and to cultivate the culture that nourishes other’s growth. We seek members who can fulfill what we might lack and willingly teach what we might not know.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/recruit%2Fthumbup.svg?alt=media&token=a21b0b04-6698-427b-906b-662a361d9ad4",
      name: " Perseverance, challenging spirit and willingness to grow"
    },
    KOR: {
      description:
        "저희는 모든 구성원이 그 분야의 전문가라고 자부하며 항상 학습하고 개발하는 문화를 갖고 있습니다. 그렇기 때문에 현재 자신이 갖고 있는 경험과 지식을 기꺼이 공유하고, 또 다른 구성원의 발전을 기꺼이 돕는 문화를 추구합니다. 저희는 배울 준비가 되어있고, 부족한 저희를 채워주고 기꺼이 가르쳐 주실 인재를 찾습니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/recruit%2Fthumbup.svg?alt=media&token=a21b0b04-6698-427b-906b-662a361d9ad4",
      name: "끈기와 도전의식을 갖고 성장해 나가실 수 있는 분"
    }
  },
  C: {
    ENG: {
      description:
        "Since our business is in the field of IOT, much portion of our tasks are often researching and studying new fields. We are looking for those who are not reluctant to challenge such new areas, and willing to do the necessary research and learning when appropriate, well. As a company that creates value that is not in the world yet,, those who are always exploring and learning new things can have fun.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/recruit%2Fhaksamo.svg?alt=media&token=1f3e6b66-edf3-4e5a-9912-a88ab0acb7ed",
      name: "Exploring spirit with passion based on sincerity"
    },
    KOR: {
      description:
        "저희의 사업영역이 IOT 분야이다 보니, 업무의 많은 부분이 새로운 분야에 대한 조사와 공부가 되는 경우가 많습니다. 이런 새로운 영역에 도전하는 것을 주저하지 않는 분, 그리고 적절할 때에 필요한 조사와 학습을 기꺼이 하시고, 또 잘하시는 분을 찾습니다. 세상에 없는 것을 만드는 Value를 가진 회사로서 늘 탐구하는 자세를 가진 분이라면 재미있게 일하실 수 있습니다",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/recruit%2Fhaksamo.svg?alt=media&token=1f3e6b66-edf3-4e5a-9912-a88ab0acb7ed",
      name: "성실함을 바탕으로 열정적으로 새로운 분야를 탐구하시는 분"
    }
  }
};

export const RECRUIT_MID_TITLE = {
  title: { ENG: "JOB DESCRIPTION", KOR: "JOB DESCRIPTION" }
};

export const RECRUIT_MID_CONTENTS = {
  A: {
    ENG: {
      bottom: {
        description:
          "(iPhone) Knowledge and experience in Objective-C\\n(Android) Knowledge and experience in Java\\n(Server) Knowledge and experience in Java, Python\\n(Embedded) Knowledge and experience in ARM, C and hardware",
        name: "Requirements"
      },
      mid: {
        description:
          "(iPhone, Android,PC) Develop ‘smart’ apps / programs for CPR training\\n(Server) Develop stable and expandable server dedicated to CPR data management\\n(Embedded) Develop hardware to be installed inside CPR manikins",
        name: "Responsibilities"
      },
      name: "I.M DEVELOPER\n[SOFTWARE]",
      top: {
        description:
          "Demonstration of expertise and craftsmanship through quality products and services, based on understanding of Framework\\nEagerness to experience new technologies such as BLE, real-time sensor data processing\\nWillingness for self-learning and self-development\\nEagerness to work along with dependable and trustworthy colleagues, and become one",
        name: "Who we are looking for"
      }
    },
    KOR: {
      bottom: {
        description:
          "(iPhone) Objective-C에 대한 지식 및 경험\n(Android) Java에 대한 지식 및 경험\n(Server) Java, Python 에 대한 지식 및 경험\n(Embedded) ARM, C, 하드웨어에 대한 지식 및 경험",
        name: "자격요건"
      },
      mid: {
        description:
          "(iPhone, Android,PC) CPR 교육을 책임지는 똑똑한 앱/프로그램을 만듭니다.\n(Server) CPR 데이터 관리를 하는 안정적이고 확장성 있는 서버를 만듭니다.\n(Embedded) CPR마네킹에 설치하는 하드웨어를 만듭니다.",
        name: "하는 일"
      },
      name: "I.M DEVELOPER\n[SOFTWARE]",
      top: {
        description:
          "Framework 에 대한 이해를 바탕으로 제품을 위해 장인 정신을 발휘할 사람\n블루투스, 실시간 센서 데이터 처리 같은 새로운 기술 들을 경험해보고 싶은 사람\n스스로 배우고 싶은 의지가 있는 사람\n믿고 맡길 수 있는 동료들 사이에서 자신도 그렇게 되고 싶은 사람",
        name: "찾는 사람"
      }
    }
  },
  B: {
    ENG: {
      bottom: {
        description:
          "3 years of experience or more, in designing circuit and productization of IoT product\\nExperience of designing ARM processor circuit is preferred\\nFirmware developer (ARM cortex A/T/M category UC OS developer) is preferred\\nExperience in mass production management is preferred\\nExperience in Baseband circuit development / Logic circuit analysis (Proficiency with measuring equipment)",
        name: "Requirements"
      },
      mid: {
        description:
          "(Circuit design) Design interiors of the product and materialize\\n(Device design) Develop prototype and design and improve existing products\\n(Development and production) Manufacture new products and manage the production",
        name: "Responsibilities"
      },
      name: "I.M DEVELOPER\n[HARDWARE]",
      top: {
        description:
          "Confidence in productization and enthusiasm for creation\\nCapacity to comprehend problems and improve as necessary\\nQuick execution and tolerance for changesQuick execution and tolerance for changes",
        name: " Who we are looking for"
      }
    },
    KOR: {
      bottom: {
        description:
          "IoT 제품의 회로 설계 및 제품화 유경험자 – 3년차 이상\nARM processor 회로 설계 경력 우대\nFirmware 개발자 우대(ARM cortex A/T/M 계열 UC OS 개발자)\n제품 양산 관리 경력자 우대\nBaseband 회로 개발 경력자 / Logic 회로분석(계측장비 사용자)",
        name: "자격요건"
      },
      mid: {
        description:
          "(회로설계) 제품 내부를 설계하고 구현합니다.\n(기구설계) 프로토타입을 제작하고 기존제품을 설계 및 개선합니다.\n(양산개발) 신제품을 양산하고 생산 관리합니다.",
        name: "하는 일"
      },
      name: "I.M DEVELOPER\n[HARDWARE]",
      top: {
        description:
          "만드는 것을 좋아하고 제품화에 자신있는 사람\n문제점 파악을 잘하고 개선할 수 있는 능력을 가진 사람\n빠른 실행력과 변화를 수용할 줄 아는 사람",
        name: "찾는 사람"
      }
    }
  },
  C: {
    ENG: {
      bottom: {
        description:
          "Proficiency with design tools such as Photoshop, Illustrator\\nProficiency with drawing tools such as Painter, Clip Studio\\nHaving high interest in and fond of learning new tools",
        name: "Requirements"
      },
      mid: {
        description:
          "(Brand design) Creates company / product identity and packaging and promotional materials\\n(UI/UX) Plan and design PC / mobile apps\\n(Graphic Design) Create illustrations, 3D images, and do other miscellaneous graphic designs",
        name: "Responsibilities"
      },
      name: "I.M DESIGNER",
      top: {
        description:
          "High understanding of CPR or passion to learn about CPR\\nCuriosity for various fields of arts or creative works\\nAdaptability to switch positioning freely as needed\\nHigh level of communication skills and teamplay capacity",
        name: "What we are looking for"
      }
    },
    KOR: {
      bottom: {
        description:
          "포토샵, 일러스트레이터 등 디자인툴 활용 능력\n페인터, 클립스튜디오 등 드로잉 툴 활용 능력\n새로운 툴에 대한 호기심이 높고 배우는 걸 좋아하시는 분",
        name: "자격요건"
      },
      mid: {
        description:
          "(브랜드디자인) 회사/제품의 아이덴티티 및 포장재 홍보물을 만듭니다.\n(UI/UX) PC/모바일 앱을 기획하고 디자인합니다.\n(Graphic Design) 일러스트, 3D이미지 제작, 기타 그래픽 디자인을 합니다.",
        name: "하는 일"
      },
      name: "I.M DESIGNER",
      top: {
        description:
          "CPR에 대한 높은 이해도 또는 배울 열정이 있는 사람\n다양한 분야의 예술 혹은 창작작업에 대한 호기심을 가진 사람\n필요에 따라 자유롭게 포지셔닝 가능한 순발력을 지닌 사람\n높은 커뮤니케이션 능력 및 팀플레이 능력을 가진 사람",
        name: "찾는 사람"
      }
    }
  },
  D: {
    ENG: {
      bottom: {
        description:
          "Being able to apply organized and strategic approach\\nHaving flexible mindset and adaptable thinking\\nCapable of responding to newly assigned tasks creatively, and learning quick",
        name: "Requirements"
      },
      mid: {
        description:
          "Communicating with design / development team and devise product strategy\\nEstablishing business relations with various entities\\nSetting domestic / international marketing strategy",
        name: "Responsibilities"
      },
      name: "I.M INNOVATOR",
      top: {
        description:
          "High level of English proficiency\\nHigh level of communication skills and teamplay capacity\\nQuick execution and willingness to learn and improve from result assessment",
        name: "What we are looking for"
      }
    },
    KOR: {
      bottom: {
        description:
          "체계적이고 전략적 사고가 가능한 분\n유연한 사고를 갖고 융통성있는 분\n처음 접하는 업무에 창의적으로 대처하고, 학습이 빠른 분",
        name: "자격요건"
      },
      mid: {
        description:
          "개발/디자인팀과의 커뮤니케이션 및 제품 방향성 고민\n외부 기업과의 비즈니스 제휴\n국내/외 마케팅 방향성 수립",
        name: "하는 일"
      },
      name: "I.M INNOVATOR",
      top: {
        description:
          "영어 소통에 뛰어난 사람\n높은 커뮤니케이션 능력 및 팀플레이 능력을 가진 사람\n빠르게 실행하고 그 결과를 통해 배울 수 있는 사람",
        name: "찾는 사람"
      }
    }
  }
};

export const RECRUIT_BOTTOM_TITLE = {
  A: { ENG: "Salary and work environment", KOR: "급여 및 근무조건" },
  B: { ENG: "HOW TO APPLY", KOR: "지원 방법" }
};

export const RECRUIT_BOTTOM_CONTENTS = {
  A: {
    ENG: {
      bottom: {
        description:
          "Full time: Monday~Friday 09:30~18:30, day offs on SaturdayㆍSundayㆍnational holidays\\nPart time: Can be discussed\\nWorkplace: Seoul office\\nLunch provided, taxi fee and dinner supported if worked overtime, day offs and leaves, employee insurance, family (personal) events supported",
        name: "Working conditions"
      },
      top: {
        description:
          "Will be decided during interview. The salary range is very flexible and may vary depending on the responsibilities assigned and previous work experience",
        name: "Salary"
      }
    },
    KOR: {
      bottom: {
        description:
          "풀타임 : 월~금 09:30~18:30, 토ㆍ일ㆍ법정공휴일 휴무 \\n파트타임 : 협의 가능합니다.\\n근무지 : 서울 본사에서 근무 가능합니다.점심 식대 지원, 야근 시 석식 및 택시비 지원, 법정 휴가, 사대보험, 경조사 지원 등 복지혜택이 있습니다.",
        name: "근무조건"
      },
      top: {
        description:
          "면접시 협의합니다. 가능한 업무 정도와 경력에 따라 매우 폭이 넓습니다.",
        name: "급여"
      }
    }
  },
  B: {
    ENG: {
      top: {
        description:
          "Submit 1 copy resume (free form, include projects participated as needed) through e-mail.\\ne-mail : recruit@imlabworld.comTel : 070-5056-3900"
      }
    },
    KOR: {
      top: {
        description:
          "자유형식의 이력서 1부(참여 / 개발하였던 프로젝트 수행경력 포함)를 이메일로 제출\ne-mail : recruit@imlabworld.com\nTel : 070-5056-3900"
      }
    }
  }
};
