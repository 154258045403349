import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeadLogo = ({ subscribeMenuStatus }) => {
  return (
    <>
      <Link to='/' className='Logo'>
        <HeadLogoWrapperImg
          src='https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/IMLAB_LOGO_NEW.png?alt=media&token=ce6aa1f1-aead-4998-9d1e-ec5d54b5ed7a'
          onClick={() => subscribeMenuStatus(false)}
          alt='IMLAB_LOGO'
          width={172}
          height={39}
        />
      </Link>
    </>
  );
};

//styled CSS
const HeadLogoWrapperImg = styled.img`
  position: relative;
  margin: 1.2vw 1.2vw 1.2vw 15vw;
  @media screen and (max-width: 1600px) {
    margin-left: 5vw;
  }
  @media screen and (max-width: 1024px) {
    position: absolute;
    top: 17px;
    left: 2%;
  }
`;
export default HeadLogo;
