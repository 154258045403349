import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { GOOGLE_MAP_API } from 'assets/Common/APIKey';

const Map = ({ language }) => {
  //This variable same as 'documnet.getElementById Method'
  const googleMapRef = useRef();

  useEffect(() => {
    //Set Map and Marker Function
    const MapInit = () => {
      //Set Map
      const map = new window.google.maps.Map(googleMapRef.current, {
        zoom: 17,
        center: { lat: 37.48665, lng: 127.03088 },
        disableDefaultUI: true,
      });

      //Set Marker In Map
      new window.google.maps.Marker({
        position: { lat: 37.48665, lng: 127.03088 },
        map,
      });
    };

    //Check for Google Map Script in HTML body
    let makeGoogleMapScript = document.getElementById('makeGoogleMapScript');

    //When Google Map Script NOT in HTML body make Google Map script Tag.
    if (!makeGoogleMapScript) {
      makeGoogleMapScript = document.createElement('script');
      makeGoogleMapScript.id = 'makeGoogleMapScript';
      document.body.appendChild(makeGoogleMapScript);
      makeGoogleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API}&libraries=places&language=en`;
      makeGoogleMapScript.addEventListener('load', MapInit);
    } else {
      MapInit();
    }
  }, []);

  return (
    <>
      <MapContainerDiv ref={googleMapRef} />
    </>
  );
};

const MapContainerDiv = styled.div`
  height: 21vw;
  width: 100%;
  @media screen and (max-width: 1024px) {
    height: 30vw;
  }
  @media screen and (max-width: 600px) {
    height: 40vw;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 23vw;
    height: 23vw;
  }
`;
export default Map;
