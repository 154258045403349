import React from "react";
import styled from "styled-components";
import RecruitTopContent from "./RecruitTopContent";
import { RECRUIT_TOP_CONTENTS } from "assets/Common/RenderData/Recruit";

const RecruitTopContentsList = ({ language }) => {
  const contents = Object.keys(RECRUIT_TOP_CONTENTS);
  return (
    <>
      {contents ? (
        <RecruitTopContentListContainerDiv>
          {contents.map(content => {
            const snnipet = RECRUIT_TOP_CONTENTS[content][language];
            return (
              <RecruitTopContent
                key={snnipet.name}
                name={snnipet.name}
                image={snnipet.image}
                description={snnipet.description}
              />
            );
          })}
        </RecruitTopContentListContainerDiv>
      ) : null}
    </>
  );
};

const RecruitTopContentListContainerDiv = styled.li`
  /*border: 3px solid blue;*/
  text-align: left;
  display: grid;
  margin-bottom: 5vw;
  grid-template-columns: 10% 90%;
  grid-row-gap: 4vw;
  grid-column-gap: 2.5vw;
  @media screen and (max-width: 1130px) {
    margin-top: 10vw;
    grid-template-columns: 100%;
    grid-row-gap: 8vw;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: flex;
    position: relative;
    left: 11vw;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
    margin-bottom: 0;
  }
`;

export default RecruitTopContentsList;
