import React, { useState } from "react";
import { DotLessUl } from "assets/Common/CSS";
import styled from "styled-components";
import NewsContent from "./NewsContent";
import { articlePageDivider } from "assets/Common/Functions";
import { NEWS_CONTENTS } from "assets/Common/RenderData/News";

const NewsContentsList = ({ language }) => {
  // eslint-disable-next-line no-unused-vars
  const [pageIndex, setPageIndex] = useState(1);
  const [preLang, afterLang] = useState(language);
  let articleList = articlePageDivider(NEWS_CONTENTS, language);
  let articles = articleList ? articleList[pageIndex] : null;

  // 1. 기사페이지 숫자 선택 감지
  const numberClickDispatcher = index => {
    setPageIndex(index);
    articles = articleList[index];
  };

  // 2. 언어 변화 감지
  const languageChangeDispatcher = () => {
    if (preLang !== language) {
      articles = articleList[pageIndex];
      afterLang(language);
    }
  };

  return (
    <>
      {articles ? (
        <>
          {languageChangeDispatcher()}
          <DotLessUl>
            {articles.map(article => (
              <li key={article.name}>
                <NewsContent
                  name={article.name}
                  url={article.url}
                  date={article.description}
                />
              </li>
            ))}
          </DotLessUl>

          {Object.keys(articleList).map(index => (
            <PageNumberP
              key={index}
              nowPageIndex={pageIndex}
              pageIndex={index}
              onClick={numberClickDispatcher.bind(null, index)}
            >
              {index}
            </PageNumberP>
          ))}
        </>
      ) : null}
    </>
  );
};

const PageNumberP = styled.p`
  position: relative;
  font-family: "OpenSans";
  cursor: pointer;
  font-size: 20px;
  left: 33vw;
  display: inline-block;
  margin: 10px;
  font-weight: ${props =>
    // eslint-disable-next-line eqeqeq
    props.nowPageIndex == props.pageIndex ? "bold" : "none"};
  color: ${props =>
    // eslint-disable-next-line eqeqeq
    props.nowPageIndex == props.pageIndex ? "#0066cc" : "none"};

  &:hover {
    color: #0066cc;
  }
  &:active {
    font-weight: bold;
  }

  @media screen and (max-width: 1024px) {
    font-size: 3vw;
    left: 37vw;
  }

  @media screen and (max-width: 600px) {
    bottom: 3vw;
    font-size: 5vw;
  }
`;
export default NewsContentsList;
