import React from "react";
import styled from "styled-components";

const RecruitTopContent = ({ name, image, description }) => {
  return (
    <>
      <RecruitTopContentImg src={image} alt={name} />
      <RecruitTopDescriptionContainerDiv>
        <RecruitTopDescriptionTitleP>{name}</RecruitTopDescriptionTitleP>
        <RecruitTopDescriptionContentP>
          {description}
        </RecruitTopDescriptionContentP>
      </RecruitTopDescriptionContainerDiv>
    </>
  );
};

const RecruitTopContentImg = styled.img`
  /*border: 3px solid darkblue;*/
  margin: 0;
  width: 100%;
  @media screen and (max-width: 1130px) {
    position: relative;
    left: 40%;
    width: 18%;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    position: absolute;
    right: 46vw;
    width: 15%;
  }
`;

const RecruitTopDescriptionContainerDiv = styled.div`
  /*border: 3px solid darkblue;*/
  font-family: NanumGothic;
  margin-right: 2.2vw;
  @media screen and (max-width: 1130px) {
    margin-right: 0;
    width: 100%;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    margin: auto 0 5vw auto;
  }
`;

const RecruitTopDescriptionTitleP = styled.p`
  font-size: 1vw;
  font-weight: bold;
  color: #0066cc;
  margin: 0;
  @media screen and (max-width: 1500px) {
    font-size: 1.5vw;
  }
  @media screen and (max-width: 1130px) {
    font-size: 2vw;
  }
  @media screen and (max-width: 700px) {
    line-height: 5vw;
    font-size: 3.4vw;
  }
  @media screen and (max-width: 500px) {
    font-size: 4vw;
  }
`;

const RecruitTopDescriptionContentP = styled.p`
  font-size: 0.9vw;
  font-weight: lighter;
  letter-spacing: 0.3px;
  line-height: 1.3vw;
  margin: 0.5vw auto auto auto;
  @media screen and (max-width: 1500px) {
    line-height: 1.5vw;
    font-size: 1.2vw;
  }
  @media screen and (max-width: 1130px) {
    line-height: 2.3vw;
    font-size: 1.7vw;
  }
  @media screen and (max-width: 700px) {
    line-height: 4vw;
    font-size: 3vw;
  }
  @media screen and (max-width: 500px) {
    line-height: 6vw;
    font-size: 3.7vw;
  }
`;

export default RecruitTopContent;
