export const CONTACT_TITLE = {
  title: {
    ENG: 'CONTACT INFO',
    KOR: 'CONTACT INFO',
  },
};

export const CONTACT_INFO = {
  KOR: {
    address: '서울특별시 서초구 남부순환로 347길 53 파이원빌딩 201호',
    Tel: '070-5056-3900',
    Fax: '0505-170-3900',
    'E-mail': 'contact@imlabword.com',
  },
  ENG: {
    address:
      'Paione Bldg. 201, 53, Nambusunhwan-ro 347-gil Seocho, Seoul, South Korea',
    Tel: '070-5056-3900',
    Fax: '0505-170-3900',
    'E-mail': 'contact@imlabword.com',
  },
};

export const FEEDBACK_TITLE = {
  title: { ENG: 'SEND US A FEEDBACK', KOR: 'SEND US A FEEDBACK' },
};

export const FEEDBACK_INFO = {
  KOR: {
    A: { title: '이름', type: 'input', essential: true },
    B: { title: '이메일', type: 'input', essential: true },
    C: { title: '전화번호', type: 'input', essential: false },
    D: { title: '제목', type: 'input', essential: false },
    E: { title: '메시지', type: 'textarea', essential: false },
  },
  ENG: {
    A: { title: 'Name', type: 'input', essential: true },
    B: { title: 'E-mail', type: 'input', essential: true },
    C: { title: 'Number to contact', type: 'input', essential: false },
    D: { title: 'Title', type: 'input', essential: false },
    E: { title: 'Message', type: 'textarea', essential: false },
  },
};
