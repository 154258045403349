/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  CacheRoute as Route,
  CacheSwitch as Switch,
} from 'react-router-cache-route';
import Main from './Pages/Main/Main';
import Home from './Pages/Home/Home';
import AboutUs from './Pages/AboutUs/AboutUs';
import Services from './Pages/Services/Services';
import News from './Pages/News/News';
import Recruit from './Pages/Recruit/Recruit';
import ContactUs from './Pages/ContactUs/ContactUs';
import Policy from './Pages/Policy/Policy';
import NoMatchPage from './Pages/NoMatchPage';
import RedirectPage from './Pages/RedirectPage';
import Landing from './Pages/Landing/Landing';
import { Redirect } from 'react-router';

/*
Body에 보여지는 페이지를 라우팅합니다.
page변화를 감지해야하므로 subscribePage 함수를 prop로 각 페이지에 넘겨줍니다.
*/

const RoutePages = ({ subscribePage, page }) => {
  const [prePage, afterPage] = useState(page);
  //페이지 변경 마다 스크롤이 최상단으로 올라올 수 있도록 설정.
  if (prePage !== page) {
    window.scrollTo(0, 0);
    afterPage(page);
  }

  return (
    <>
      <Switch>
        <Route
          path='/'
          exact
          render={(props) => (
            <Main subscribePage={subscribePage} location='main' />
          )}
        />
        <Route
          path='/company'
          exact
          render={(props) => (
            <Home subscribePage={subscribePage} location='home' />
          )}
        />
        <Route
          path='/company/about-us'
          render={(props) => (
            <AboutUs
              subscribePage={subscribePage}
              location={props.location.pathname.split('/')[2]}
            />
          )}
        />
        <Route
          path='/company/services'
          render={(props) => (
            <Services
              subscribePage={subscribePage}
              location={props.location.pathname.split('/')[2]}
            />
          )}
        />
        <Route
          path='/company/news'
          render={(props) => (
            <News
              subscribePage={subscribePage}
              location={props.location.pathname.split('/')[2]}
            />
          )}
        />
        <Route
          path='/company/recruit'
          render={(props) => (
            <Recruit
              subscribePage={subscribePage}
              location={props.location.pathname.split('/')[2]}
            />
          )}
        />
        <Route
          path='/company/contact-us'
          render={(props) => (
            <ContactUs
              subscribePage={subscribePage}
              location={props.location.pathname.split('/')[2]}
            />
          )}
        />
        <Route
          path='/company/privacy-policy'
          render={(props) => (
            <Policy
              subscribePage={subscribePage}
              location={props.location.pathname.split('/')[2]}
            />
          )}
        />
        <Route
          exact
          path='/remote'
          render={(props) => (
            <Landing
              subscribePage={subscribePage}
              location={props.location.pathname.split('/')[2]}
            />
          )}
        />
        <Redirect from='/remote/*' to='/remote' />
        <Route
          path='/team'
          component={() => {
            // window.location.href = 'https://www.notion.so/I-M-LAB-8c54be12c6ab474e85344f75843b66df';
            window.location.replace(
              'https://imlab.notion.site/I-M-LAB-8c54be12c6ab474e85344f75843b66df'
            );
            return (
              <RedirectPage
                subscribePage={subscribePage}
                location='not-found'
              />
            );
          }}
        />
        <Route
          path='/team'
          render={(props) => (
            <RedirectPage subscribePage={subscribePage} location='not-found' />
          )}
        />
        <Route
          render={(props) => (
            <NoMatchPage subscribePage={subscribePage} location='not-found' />
          )}
        />
      </Switch>
    </>
  );
};

export default RoutePages;
