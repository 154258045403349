import React, { useContext } from "react";
import styled from "styled-components";
import FootTopLeft from "./FootTopLeft";
import FootTopRight from "./FootTopRight";
import { selectLanguage, pageInformations } from "assets/Common/ContextAPI";

const FootTop = () => {
  const language = useContext(selectLanguage);
  const PAGE_INFO = useContext(pageInformations);
  const footTopLeftData = Object.values(PAGE_INFO).slice(1, -2);
  const footTopRightData = Object.values(PAGE_INFO)[6][language];

  return (
    <FootTopContainerDiv>
      <FootTopLeft language={language} data={footTopLeftData} />
      <FootTopRight data={footTopRightData} />
    </FootTopContainerDiv>
  );
};

const FootTopContainerDiv = styled.div`
  background-color: #252525;
  padding: 0.7vw;
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-transform: capitalize;
  font-size: 15px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 100%;
    height: 4vw;
    font-size: 2.5vw;
  }
  @media screen and (max-width: 600px) {
    height: 6vw;
    font-size: 4vw;
  }
`;
export default FootTop;
