import { createElement } from "react";

//새로운 창에서 페이지를 열 수 있도록 하는 함수입니다.
export const newBrowserOpener = url => {
  window.open(url);
};
/*
\n를 인식하여 <p>,<br> tag를 생성해주는 함수입니다.
첫번째인자:  data
두번째인자: <p> tag 사이의 margin값 입니다.
*/
export const newLineMaker = (data, pTagMarginValue = "1vw") => {
  data = data.split("\n").length === 1 ? data.split("\\n") : data.split("\n");

  const reactHTML = data.map((content, index) =>
    content
      ? createElement(
          "p",
          { key: index, style: { margin: pTagMarginValue } },
          content
        )
      : createElement("br", { key: index })
  );
  return reactHTML;
};

//기사 데이터를 DB로부터 가져와 페이지를 분기해주는 함수입니다.
export const articlePageDivider = (data, language) => {
  let articleList = {};
  let articleInPage = [];
  let pageIndex = 0;

  const dateFormat = (date, lang) => {
    if (lang === "KOR") {
      return Number(date.replace(/["년"|"월"|"일"|" "]/gi, ""));
    } else {
      return Number(date.replace(/["/"]/gi, ""));
    }
  };

  const unOrderData = data.map(curr => curr[language]);
  const orderData = unOrderData.sort((a, b) => {
    if (
      new Date(dateFormat(a.description, language)) >
      new Date(dateFormat(b.description, language))
    ) {
      return -1;
    } else if (
      new Date(dateFormat(a.description, language)) ===
      new Date(dateFormat(b.description, language))
    ) {
      return 0;
    } else {
      return 1;
    }
  });

  orderData.forEach((news, index) => {
    articleInPage.push(news);
    if (articleInPage.length === 11 || index === orderData.length - 1) {
      articleList[++pageIndex] = articleInPage;
      articleInPage = [];
    }
  });
  return articleList;
};

export const countryCodeGetter = async () => {
  let countryCode;
  const jsonGetter = async address => {
    let json = await fetch(address);
    json = await json.json();
    return json;
  };
  //IP 수집 API를 이용해 IP 정보를 불러오고, IP정보를 이용해 국가를 구분짓는다.
  const countryInfo = await jsonGetter("https://api.ip.pe.kr/json/");
  const { country_code } = countryInfo;
  //IP정보와 국가정보모두 잘 불러왔다면 불러온 정보를 이용해 국가코드를 할당한다.
  if (country_code) {
    countryCode = country_code;
  }

  //IP 정보와 국가정보 중 하나라도 못불러왔다면, 브라우저 기본 셋팅 언어를 이용해 국가코드를 얻는다.
  else {
    //크롬, 사파리
    if (navigator.language) {
      countryCode = navigator.language.slice(0, 2).toUpperCase();
    }
    // IE
    else if (navigator.userLanguage) {
      countryCode = navigator.userLanguage.slice(0, 2).toUpperCase();
    }
    // IE
    else if (navigator.systemLanguage) {
      countryCode = navigator.systemLanguage.slice(0, 2).toUpperCase();
    }
  }
  return countryCode;
};

//[Deprecated]DB로부터 데이터를 가져와 setState해주는 함수입니다.
//export const getDBdataSetter = (columnName, setStateVariable) => {
//  //DB로부터 데이터를 가져와 자바스크립트 객체로 가공하는 함수입니다.
//  import("../../firebase").then(({ db }) => {
//    const DBdataGetter = async collection => {
//      const DBData = await db.collection(collection).get();
//      let getData = {};
//      DBData.forEach(async doc => {
//        getData[doc.id] = doc.data();
//      });
//      return getData;
//    };
//    (async () => {
//      const setData = await DBdataGetter(columnName);
//      setStateVariable(setData);
//    })();
//  });
//};
