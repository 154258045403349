import React from 'react';
import styled from 'styled-components';
import { PARTNERS_TITLE } from 'assets/Common/RenderData/Home';

import PartnerContentList from './PartnerContentList';

const Partners = ({ language }) => (
  <PartnersContainerDiv>
    <PartnersTitleH1>{PARTNERS_TITLE[language]}</PartnersTitleH1>
    <PartnersDivideHr />
    <PartnerContentList />
  </PartnersContainerDiv>
);

//Styled CSS
const PartnersContainerDiv = styled.div`
  /*border: 3px solid black;*/
  text-align: center;
  margin-top: 10vw;
  @media screen and (max-width: 600px) {
    margin-top: 25vw;
  }
`;

const PartnersTitleH1 = styled.h1`
  font-family: MontserratRegular, sans-serif;
  font-weight: 500;
  font-size: 45px;
  color: #303030;
  @media screen and (max-width: 1600px) {
    font-size: 40px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 5.5vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 6.5vw;
  }
`;

const PartnersDivideHr = styled.div`
  width: 40vw;
  border: 0;
  height: 1px;
  margin: 4vw auto 1vw auto;
  background: #ccc;

  @media screen and (max-width: 1024px) {
    width: 80vw;
  }
`;
export default Partners;
