import React, { useContext } from 'react';
import styled from 'styled-components';
import { selectLanguage } from 'assets/Common/ContextAPI';
import Members from './Members/Members';
import Adviser from './Advisor/Adviser';

const People = () => {
  const language = useContext(selectLanguage);
  return (
    <PeopleContainerDiv>
      <Members language={language} />
      <Adviser language={language} />
    </PeopleContainerDiv>
  );
};

//Styled CSS
const PeopleContainerDiv = styled.div`
  text-align: center;
  font-family: NanumGothic, sans-serif;
  background-color: #f4f4f4;
  margin: 5vw auto 0 auto;
  padding-top: 5vw;
  padding-bottom: 5vw;
`;

export default People;
