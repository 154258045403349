import React, { useContext } from "react";
import styled from "styled-components";
import { selectLanguage } from "assets/Common/ContextAPI";
import ContactInfo from "./ContactInfo/ContactInfo";
import FeedbackForm from "./SendFeedback/FeedbackForm";
const ContactUs = ({ subscribePage, location }) => {
  subscribePage(location);

  const language = useContext(selectLanguage);

  return (
    <ContactUsContainerDiv>
      <FeedbackForm language={language} />
      <ContactInfo language={language} />
    </ContactUsContainerDiv>
  );
};

const ContactUsContainerDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 3vw auto 3vw auto;
  @media screen and (max-width: 1600px) {
    width: 80%;
  }
  @media screen and (max-width: 1024px) {
    margin: 10vw auto 0 auto;
    flex-direction: column;
  }
  @media screen and (max-width: 600px) {
    margin: inherit auto inherit auto;
    padding-bottom: 10vw;
  }
`;

export default ContactUs;
