import React, { useContext } from 'react';
import styled from 'styled-components';
import { ABOUT_ADVISER } from 'assets/Common/RenderData/About';
import { selectLanguage } from 'assets/Common/ContextAPI';
import AdviserContent from './AdviserContent';

const AdviserContentList = () => {
  const language = useContext(selectLanguage);
  const advicers = Object.keys(ABOUT_ADVISER);

  return (
    <>
      {advicers ? (
        <AdviserContentContainerDiv>
          {advicers.map((advicer) => {
            const snnipet = ABOUT_ADVISER[advicer][language];
            return (
              <AdviserContentWrapperDiv key={snnipet.name}>
                <AdviserContent
                  name={snnipet.name}
                  description={snnipet.description}
                  school={snnipet.school}
                />
              </AdviserContentWrapperDiv>
            );
          })}
        </AdviserContentContainerDiv>
      ) : null}
    </>
  );
};

//Styled CSS
const AdviserContentContainerDiv = styled.div`
  /*border: 1px solid red;*/
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
`;

const AdviserContentWrapperDiv = styled.div`
  /*border: 1px solid blue;*/
  width: 300px;
  height: 130px;
  margin: 0 0 5vw 0;
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 30vw;
    margin: 3vw 0 3vw 0;
  }
`;

export default AdviserContentList;
