import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  MEMBERS_TITLE,
  MEMBERS_OPERATION,
  MEMBERS_DEV,
  MEMBERS_UX,
  MEMBERS_QA,
  MEMBERS_BIZ,
} from 'assets/Common/RenderData/About';
import { selectLanguage } from 'assets/Common/ContextAPI';
import MemberContent from './MemberContent';

const MemberContentList = () => {
  const language = useContext(selectLanguage);

  const members_operation = Object.keys(MEMBERS_OPERATION);
  const members_dev = Object.keys(MEMBERS_DEV);
  const members_ux = Object.keys(MEMBERS_UX);
  const members_qa = Object.keys(MEMBERS_QA);
  const members_biz = Object.keys(MEMBERS_BIZ);

  return (
    <>
      <MemberSubtitleH2>{MEMBERS_TITLE.OPERATION}</MemberSubtitleH2>
      {members_operation && (
        <MemberContentContainerDiv>
          {members_operation.map((member) => {
            const member_lang_data = MEMBERS_OPERATION[member][language];

            return (
              <MemberContentWrapperDiv key={member_lang_data.name}>
                <MemberContent
                  name={member_lang_data.name}
                  image={member_lang_data.image}
                  description={member_lang_data.description}
                  school={member_lang_data.school}
                  email={member_lang_data.email}
                />
              </MemberContentWrapperDiv>
            );
          })}
        </MemberContentContainerDiv>
      )}
      <MemberSubtitleH2>{MEMBERS_TITLE.DEV}</MemberSubtitleH2>
      {members_dev && (
        <MemberContentContainerDiv>
          {members_dev.map((member) => {
            const member_lang_data = MEMBERS_DEV[member][language];

            return (
              <MemberContentWrapperDiv key={member_lang_data.name}>
                <MemberContent
                  name={member_lang_data.name}
                  image={member_lang_data.image}
                  description={member_lang_data.description}
                  school={member_lang_data.school}
                  email={member_lang_data.email}
                />
              </MemberContentWrapperDiv>
            );
          })}
        </MemberContentContainerDiv>
      )}
      <MemberSubtitleH2>{MEMBERS_TITLE.UX}</MemberSubtitleH2>
      {members_ux && (
        <MemberContentContainerDiv>
          {members_ux.map((member) => {
            const member_lang_data = MEMBERS_UX[member][language];

            return (
              <MemberContentWrapperDiv key={member_lang_data.name}>
                <MemberContent
                  name={member_lang_data.name}
                  image={member_lang_data.image}
                  description={member_lang_data.description}
                  school={member_lang_data.school}
                  email={member_lang_data.email}
                />
              </MemberContentWrapperDiv>
            );
          })}
        </MemberContentContainerDiv>
      )}
      <MemberSubtitleH2>{MEMBERS_TITLE.QA}</MemberSubtitleH2>
      {members_qa && (
        <MemberContentContainerDiv>
          {members_qa.map((member) => {
            const member_lang_data = MEMBERS_QA[member][language];

            return (
              <MemberContentWrapperDiv key={member_lang_data.name}>
                <MemberContent
                  name={member_lang_data.name}
                  image={member_lang_data.image}
                  description={member_lang_data.description}
                  school={member_lang_data.school}
                  email={member_lang_data.email}
                />
              </MemberContentWrapperDiv>
            );
          })}
        </MemberContentContainerDiv>
      )}
      <MemberSubtitleH2>{MEMBERS_TITLE.BIZ}</MemberSubtitleH2>
      {members_biz && (
        <MemberContentContainerDiv>
          {members_biz.map((member) => {
            const member_lang_data = MEMBERS_BIZ[member][language];

            return (
              <MemberContentWrapperDiv key={member_lang_data.name}>
                <MemberContent
                  name={member_lang_data.name}
                  image={member_lang_data.image}
                  description={member_lang_data.description}
                  school={member_lang_data.school}
                  email={member_lang_data.email}
                />
              </MemberContentWrapperDiv>
            );
          })}
        </MemberContentContainerDiv>
      )}
    </>
  );
};

//Styled CSS
const MemberSubtitleH2 = styled.h2`
  font-family: MontserratRegular, sans-serif;
  font-weight: 500;
  font-size: 32px;
  @media screen and (max-width: 600px) {
    font-size: 6.5vw;
  }
`;

const MemberContentContainerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const MemberContentWrapperDiv = styled.div`
  /*border: 1px solid black;*/
  width: 377px;
  margin: 0 0 5vw 0;
  @media screen and (max-width: 600px) {
    margin-top: 10vw;
  }
`;

export default MemberContentList;
