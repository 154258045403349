import React from 'react';
import styled from 'styled-components';
import AdviserContentList from './AdviserContentList';
import { ABOUT_ADVISER_TITLE } from 'assets/Common/RenderData/About';

const Adviser = ({ language }) => (
  <>
    <AdviserTitleH1>{ABOUT_ADVISER_TITLE.title[language]}</AdviserTitleH1>
    <AdviserDivideHr />
    <AdviserContentList />
  </>
);

//Styled CSS
const AdviserTitleH1 = styled.h1`
  font-family: MontserratRegular, sans-serif;
  font-size: 40px;
  font-weight: 500;
  @media screen and (max-width: 1024px) {
    margin-top: 100px;
    font-size: 6vw;
  }
  @media screen and (max-width: 600px) {
    margin-top: 80px;
    font-size: 6vw;
  }
`;

const AdviserDivideHr = styled.hr`
  width: 30%;
  margin: 2vw auto 5vw auto;
  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;

export default Adviser;
