import React from "react";
import styled from "styled-components";

const MemberContent = ({ name, image, description, school, email }) => {
  return (
    <>
      <MemberContentImg src={image} alt="name" />
      <MemberContentWrapperDiv>
        <MemberContentNameP>{name}</MemberContentNameP>
        {[description, school, email].map(curr => (
          <MemberContentDetailInfoP key={curr}>{curr}</MemberContentDetailInfoP>
        ))}
      </MemberContentWrapperDiv>
    </>
  );
};

//Styled CSS
const MemberContentImg = styled.img`
  width: 40%;
`;
const MemberContentWrapperDiv = styled.div`
  /*border: 1px solid blue;*/
`;

const MemberContentNameP = styled.p`
  font-size: 20px;
  font-weight: 650;
  letter-spacing: 0.7px;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    font-size: 5vw;
  }
`;

const MemberContentDetailInfoP = styled.p`
  color: gray;
  font-family: OpenSans, sans-serif;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.7px;
  line-height: 25px;
  margin: 0;

  @media screen and (max-width: 600px) {
    line-height: 5vw;
    font-size: 4.5vw;
  }
`;

export default MemberContent;
