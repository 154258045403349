import React from "react";
import styled from "styled-components";
import FootTop from "./FootTop/FootTop";
import FootBottom from "./FootBottom/FootBottom";

const Footer = () => {
  return (
    <FooterContainerDiv>
      <FootTop />
      <FootBottom />
    </FooterContainerDiv>
  );
};

const FooterContainerDiv = styled.div`
  font-family: OpenSans, sans-serif;
`;

export default Footer;
