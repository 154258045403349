import React from "react";
import styled from "styled-components";
import ServiceContentRight from "./ServiceContentRight";
import ServiceContentLeft from "./ServiceContentLeft";
import { PRODUCT_LIST } from "assets/Common/RenderData/Services";

const ServiceContentsList = ({ language }) => {
  const products = Object.keys(PRODUCT_LIST);
  return (
    <div>
      {products ? (
        <>
          <ProductListWrapperUl>
            {products.map((product, index) => {
              const photo = PRODUCT_LIST[product][language];
              return (
                <ProductListLi key={product}>
                  {//사진 position 정보에 따라, 조건부 렌더링을 실시.
                  photo.position === "left" ? (
                    <ServiceContentLeft
                      language={language}
                      image={photo.image}
                      description={photo.description}
                      url={photo.url}
                      isAok={index === 0}
                      logo={photo.logo}
                    />
                  ) : (
                    <ServiceContentRight
                      language={language}
                      image={photo.image}
                      description={photo.description}
                      url={photo.url}
                      logo={photo.logo}
                    />
                  )}
                  {index < products.length - 1 ? <ProductDivdeLineHr /> : null}
                </ProductListLi>
              );
            })}
          </ProductListWrapperUl>
        </>
      ) : null}
    </div>
  );
};

const ProductListWrapperUl = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
  width: 71%;
  margin: auto;
  @media screen and (max-width: 1600px) {
    width: 95%;
  }
`;

const ProductListLi = styled.li`
  /*border: 3px solid green;*/
  font-family: NanumGothic, sans-serif;
  font-size: 1.1vw;
  color: #979797;
  letter-spacing: 0.1px;
  word-break: keep-all;
  line-height: 1.5vw;
  position: relative;
  width: 100%;
  height: 25vw;
  @media screen and (max-width: 1600px) {
    height: 30vw;
  }
  @media screen and (max-width: 600px) {
    height: auto;
  }
`;

const ProductDivdeLineHr = styled.hr`
  position: absolute;
  bottom: -1vw;
  width: 100%;
  border: 0;
  height: 1px;
  background: #ccc;
  @media screen and (max-width: 600px) {
    position: static;
    margin: 10vw auto 10vw auto;
    width: 100%;
  }
`;

export default ServiceContentsList;
