import React, { useContext } from "react";
import styled from "styled-components";
import NewsContentsList from "./NewsContentsList";
import { selectLanguage } from "assets/Common/ContextAPI";

const News = ({ subscribePage, location }) => {
  const language = useContext(selectLanguage);
  subscribePage(location);

  return (
    <NewsContatinerDiv>
      <NewsContentsList language={language} />
    </NewsContatinerDiv>
  );
};

//Styled CSS
const NewsContatinerDiv = styled.div`
  /*border : 1px solid red;*/
  position: relative;
  margin: 5vw 10vw auto 15vw;

  @media screen and (max-width: 1024px) {
    width: 90%;
    margin: 10vw auto auto auto;
  }
  @media screen and (max-width: 600px) {
    width: 90%;
    margin: 12vw auto auto auto;
    font-size: 4vw;
  }
`;

export default News;
