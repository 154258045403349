import React from "react";
import styled from "styled-components";
import { RECRUIT_TOP_TITLE } from "assets/Common/RenderData/Recruit";
import RecruitTopContentsList from "./RecruitTopContentsList";

const RecruitTop = ({ language }) => (
  <>
    <RecruitTopTitleP>{RECRUIT_TOP_TITLE.title[language]}</RecruitTopTitleP>
    <RecruitTopContentsList language={language} />
  </>
);

const RecruitTopTitleP = styled.p`
  font-family: OpenSans, sans-serif;
  font-weight: 500;
  color: #5d5d5d;
  font-size: 2.3vw;
  margin-top: 4vw;
  margin-bottom: 4vw;
  @media screen and (max-width: 1600px) {
    font-size: 3vw;
  }
  @media screen and (max-width: 1024px) {
    font-size: 4.5vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 6.5vw;
  }
`;

export default RecruitTop;
