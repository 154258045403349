import React from 'react';
import styled from 'styled-components';
import { RECRUIT_MID_TITLE } from 'assets/Common/RenderData/Recruit';
import RecruitMidContentsList from './RecruitMidContentsList';

const RecruitMid = ({ language }) => (
  <>
    <RecruitMidPartTitleP>
      {RECRUIT_MID_TITLE.title[language]}
    </RecruitMidPartTitleP>
    {/* <RecruitMidContentsList language={language} /> */}

    <ViewLatestJobPostingButton
      onClick={() =>
        window.open(
          'https://imlab.notion.site/I-M-LAB-8c54be12c6ab474e85344f75843b66df'
        )
      }
    >
      {language === 'KOR'
        ? '최신 채용 공고 확인하기'
        : 'View latest job postings'}
    </ViewLatestJobPostingButton>
  </>
);

//Styled CSS아마
const RecruitMidPartTitleP = styled.p`
  font-family: MontserratRegular;
  font-size: 2.3vw;
  color: #5d5d5d;
  margin-top: 8vw;
  margin-bottom: 1vw;
  @media screen and (max-width: 1500px) {
    font-size: 2.9vw;
  }
  @media screen and (max-width: 1130px) {
    margin-top: 10vw;
    margin-bottom: 1vw;
    font-size: 3.8vw;
  }
  @media screen and (max-width: 700px) {
    margin-top: 20vw;
    font-size: 7vw;
  }
  @media screen and (max-width: 500px) {
    font-size: 8vw;
  }
`;

const ViewLatestJobPostingButton = styled.button`
  cursor: pointer;
  margin-top: 25px;
  font-family: MontserratBold, sans-serif;
  font-size: 15px;
  color: #fff;
  background-color: #0677b4;
  width: 50%;
  height: 50px;
  outline: 0;
  border: 0;
  border-radius: 2px;

  &:hover {
    border: 2px solid #0677b4;
    background-color: #fff;
    color: #0677b4;
    transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
  }

  &:active {
    color: #fff;
    background-color: #0677b4;
  }
  @media screen and (max-width: 1600px) {
    width: 60%;
    height: 40px;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 5vw;
    font-size: 2.5vw;
  }

  @media screen and (max-width: 600px) {
    height: 13vw;
    font-size: 5vw;

    &:hover {
      border: 0;
      background-color: #0677b4;
      color: #fff;
    }

    &:active {
      border: 2px solid #0677b4;
      background-color: #fff;
      color: #0677b4;
      transition: background-color 0.5s;
      -o-transition: background-color 0.5s;
      -moz-transition: background-color 0.5s;
      -webkit-transition: background-color 0.5s;
    }
  }
`;

export default RecruitMid;
