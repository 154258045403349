/*
ContextAPI는 변수들을 React 에서 전역으로 관리할 수 있도록 만든 API입니다.
Context는 전역변수들을 담는 공간으로서 'createContext'를 이용해 공간을 만들어줍니다.
*/
import { createContext } from "react";

// 1. 언어선택(languageSelect) 사항을 Context에 담아 관리합니다.
export const selectLanguage = createContext();

// 2. 페이지선택(pageSelect) 사항을 Context에 담아 관리합니다.
export const selectPage = createContext();

// 3. 메뉴정보(pageInformations)를 db에서 가져와 메뉴, footer메뉴, no match Page 등 에서 전역적으로 사용할 수 있도록 합니다.
export const pageInformations = createContext();
