import React from "react";
import styled from "styled-components";
import { INTRO_TITLE, INTRO_PAGE } from "assets/Common/RenderData/Home";
import IntroContentList from "./IntroContentList";

const Intro = ({ language }) => (
  <IntroContainerDiv>
    <IntroTitleH1>{INTRO_TITLE.title[language]}</IntroTitleH1>
    <IntroSubTitleH3>{INTRO_TITLE.subTitle[language]}</IntroSubTitleH3>
    <IntroDivideHr />
    <IntroContentList language={language} introPage={INTRO_PAGE} />
  </IntroContainerDiv>
);

const IntroContainerDiv = styled.div`
  /*border: 3px solid red;*/
  font-family: MontserratRegular, sans-serif;
  text-align: center;
  padding-top: 100px;
  margin: auto 4vw auto 4vw;
  @media screen and (max-width: 1024px) {
    padding-top: 10vw;
  }
`;

const IntroTitleH1 = styled.h1`
  font-weight: 500;
  font-size: 50px;
  letter-spacing: 1px;
  margin: 0 auto 0 auto;
  color: #303030;
  @media screen and (max-width: 1600px) {
    font-size: 45px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 6vw;
  }
`;

const IntroSubTitleH3 = styled.h3`
  font-size: 40px;
  font-weight: lighter;
  text-transform: uppercase;
  margin-top: 2vw;
  letter-spacing: 1px;
  color: #5d5d5d;
  @media screen and (max-width: 1600px) {
    font-size: 35px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 5vw;
  }
`;

const IntroDivideHr = styled.div`
  width: 40%;
  border: 0;
  height: 1px;
  margin: 4vw auto 1vw auto;
  background: #ccc;
  @media screen and (max-width: 1600px) {
    width: 60%;
  }
  @media screen and (max-width: 1024px) {
    width: 80vw;
  }
`;

export default Intro;
