import React from 'react';
import Value from './Value/Value';
import People from './People/People';

const AboutUs = ({ subscribePage, location }) => {
  subscribePage(location);

  return (
    <>
      <Value />
      <People />
    </>
  );
};

export default AboutUs;
