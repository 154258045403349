import React, { useState, useEffect } from "react";
import Screen from "./Screen";
import { PAGE_INFO } from "assets/Common/RenderData/Main";
import "./App.css";
import {
  selectLanguage,
  selectPage,
  pageInformations
} from "assets/Common/ContextAPI";

const App = () => {
  //초기 browser 언어셋팅 :  기본은 KOR
  const [language, setLanguages] = useState("KOR");
  //초기 browser screen setting
  const [page, setPage] = useState("main");

  // 메뉴 변경 시 마다 브라우저 탭 이름(<title></title>) 설정(즉시실행 함수)
  (function browserTabNameSetter() {
    const fixedTitle = "I.M.LAB";
    if (page === "main") {
      document.title = fixedTitle;
    } else {
      document.title = `${page
        .replace("-", " ")
        .toUpperCase()} | ${fixedTitle}`;
    }
  })();

  useEffect(() => {
    import("./assets/Common/Functions").then(({ countryCodeGetter }) => {
      countryCodeGetter().then(countryCode =>
        setLanguages(
          countryCode === "KR" || countryCode === "KO" ? "KOR" : "ENG"
        )
      );
    });

    //IE 대응 에러 출력 코드(즉시실행 함수)
    (function IEMessageGiver() {
      const agent = navigator.userAgent.toLowerCase();
      if (
        (navigator.appName === "Netscape" &&
          navigator.userAgent.search("Trident") !== -1) ||
        agent.indexOf("msie") !== -1
      ) {
        alert(
          "Please use ANOTHER BROWSER.\nOur Homepage does NOT support Internet Explorer."
        );
      }
    })();
  }, []);

  return (
    <pageInformations.Provider value={PAGE_INFO}>
      <selectLanguage.Provider value={language}>
        <selectPage.Provider value={page}>
          <Screen
            //아래 함수는 '언어선택'을 감지하는 함수입니다.
            subscribeLanguage={language => setLanguages(language)}
            //아래 함수는 '페이지 변화'를 감지하는 함수입니다.
            subscribePage={page => setPage(page)}
          />
        </selectPage.Provider>
      </selectLanguage.Provider>
    </pageInformations.Provider>
  );
};

export default App;
