export const ABOUT_TITLE = {
  title: {
    KOR: {
      name: '생명을 구하고자 하는 것은 인간의 본능이며\n보편적 가치입니다.',
      description:
        '우리는 헬스케어 분야에서 이러한 진정성에 기반한 좋은 제품들을 만들고자 하는 비전을 가지고 있습니다.\n우리의 제품들이 생명을 위하며, 나아가 보다 많은 사람들을 도왔으면 합니다.\n앞으로도 지속적으로 보다 나은 세상을 만드는 데에 기여해 나가겠습니다.\n▼',
    },
    ENG: {
      name: 'To save life is human instinct and universal value.',
      description:
        'We have a vision to create quality products based on sincerity and integrity, in healthcare fields.\nWe wish that our products will be for lives and furthermore, will help to save more lives and aid more people.\nWe will continue to contribute towards creating a better world.\n▼',
    },
  },
};

export const ABOUT_VALUE_TITLE = {
  title: {
    KOR: '[ I.M.LAB CORE VALUE ]',
    ENG: '[ I.M.LAB CORE VALUE ]',
  },
};
export const ABOUT_MEMBERS_TITLE = {
  title: {
    KOR: 'MEMBERS',
    ENG: 'MEMBERS',
  },
};
export const ABOUT_ADVISER_TITLE = {
  title: {
    KOR: 'ADVISER',
    ENG: 'ADVISER',
  },
};
export const ABOUT_VALUE = {
  '1': {
    ENG: {
      description: 'Create a special product that is not in the world.',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/icon%2F1Innovation.svg?alt=media&token=faaa6717-93b2-4680-b381-c633ded16a2d',
      name: 'INNOVATION',
    },
    KOR: {
      description: '세상에 없는 특별한 제품을 만듭니다.',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/icon%2F1Innovation.svg?alt=media&token=faaa6717-93b2-4680-b381-c633ded16a2d',
      name: 'INNOVATION',
    },
  },
  '2': {
    ENG: {
      description: 'Provide a fun experience.',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/icon%2F2fun.svg?alt=media&token=333fb8f5-d768-4f32-9083-9c4048fdf1aa',
      name: 'FUN',
    },
    KOR: {
      description: '재미있는 경험을 제공합니다.',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/icon%2F2fun.svg?alt=media&token=333fb8f5-d768-4f32-9083-9c4048fdf1aa',
      name: 'FUN',
    },
  },
  '3': {
    ENG: {
      description: 'Provide a better solution.',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/icon%2F3solution.svg?alt=media&token=c5dad607-c2ce-4b50-b101-0e0e738d0606',
      name: 'BETTER SOLUTION',
    },
    KOR: {
      description: '더 나은 솔루션을 제공합니다.',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/icon%2F3solution.svg?alt=media&token=c5dad607-c2ce-4b50-b101-0e0e738d0606',
      name: 'BETTER SOLUTION',
    },
  },
  '4': {
    ENG: {
      description: 'Create a better world convenient.',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/icon%2F4life.svg?alt=media&token=e9347e2b-1b81-46f3-968a-3f6460478653',
      name: 'BETTER LIFE',
    },
    KOR: {
      description: '편리한 더 나은 세상을 만듭니다.',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/icon%2F4life.svg?alt=media&token=e9347e2b-1b81-46f3-968a-3f6460478653',
      name: 'BETTER LIFE',
    },
  },
};

export const MEMBERS_TITLE = {
  OPERATION: 'Operation',
  DEV: 'Team Dev',
  UX: 'Team UX',
  QA: 'Team QA',
  BIZ: 'Team Biz',
};

export const MEMBERS_OPERATION = {
  YERAM: {
    KOR: {
      name: '권예람',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EC%98%88%EB%9E%8C_r.png?alt=media&token=6a5f7f94-2b61-4b92-a23f-a96bf294c79e',
      description: 'CEO',
      school: 'KAIST GSCT',
      email: 'yrkwon@imlabworld.com',
    },
    ENG: {
      name: 'Yeram Kwon',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EC%98%88%EB%9E%8C_r.png?alt=media&token=6a5f7f94-2b61-4b92-a23f-a96bf294c79e',
      description: 'CEO',
      school: 'KAIST GSCT',
      email: 'yrkwon@imlabworld.com',
    },
  },
};

export const MEMBERS_DEV = {
  HYEONGMOOK: {
    ENG: {
      name: 'Hyeongmook Lee',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%ED%98%95%EB%AC%B5_r.png?alt=media&token=fa0e5406-a5ec-41fa-9c03-dc091378a269',
      description: 'CTO / R&D Manager',
      school: 'KAIST',
      email: 'hmlee@imlabworld.com',
    },
    KOR: {
      name: '이형묵',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%ED%98%95%EB%AC%B5_r.png?alt=media&token=fa0e5406-a5ec-41fa-9c03-dc091378a269',
      description: 'CTO / R&D Manager',
      school: 'KAIST',
      email: 'hmlee@imlabworld.com',
    },
  },
  CHULSEUNG: {
    ENG: {
      name: 'Chulseung Yoo',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EC%B2%A0%EC%8A%B9_r.png?alt=media&token=4123e6b1-fb15-4d92-8308-d83a9758437f',
      description: 'Team Lead / Software Engineer',
      school: 'Hanyang Univ.',
      email: 'csyoo@imlabworld.com',
    },
    KOR: {
      name: '유철승',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EC%B2%A0%EC%8A%B9_r.png?alt=media&token=4123e6b1-fb15-4d92-8308-d83a9758437f',
      description: 'Team Lead / Software Engineer',
      school: 'Hanyang Univ.',
      email: 'csyoo@imlabworld.com',
    },
  },
  JARAM: {
    ENG: {
      name: 'Jaram Park',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%8C%E1%85%A1%E1%84%85%E1%85%A1%E1%86%B7_r.png?alt=media&token=2f7502d6-f472-48a9-86e7-bb7dd853cc76',
      description: 'Project Lead / Data Scientist',
      school: 'KAIST',
      email: 'jaram.park@imlabworld.com',
    },
    KOR: {
      name: '박자람',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%8C%E1%85%A1%E1%84%85%E1%85%A1%E1%86%B7_r.png?alt=media&token=2f7502d6-f472-48a9-86e7-bb7dd853cc76',
      description: 'Project Lead / Data Scientist',
      school: 'KAIST',
      email: 'jaram.park@imlabworld.com',
    },
  },
  HYUNGCHAN: {
    ENG: {
      name: 'Hyungchan Jung',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%92%E1%85%A7%E1%86%BC%E1%84%8E%E1%85%A1%E1%86%AB_r.png?alt=media&token=036f1ce6-b00c-4cfb-8bd0-79c3fe6755bf',
      description: 'Software Engineer',
      school: 'Yonsei Univ.',
      email: 'hcjung@imlabworld.com',
    },
    KOR: {
      name: '정형찬',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%92%E1%85%A7%E1%86%BC%E1%84%8E%E1%85%A1%E1%86%AB_r.png?alt=media&token=036f1ce6-b00c-4cfb-8bd0-79c3fe6755bf',
      description: 'Software Engineer',
      school: 'Yonsei Univ.',
      email: 'hcjung@imlabworld.com',
    },
  },
  JINWOOK: {
    ENG: {
      name: 'Jinwook Song',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EC%A7%84%EC%9A%B1_r.png?alt=media&token=ac625d29-fc12-4f6f-baa1-135da394caf0',
      description: 'Software Engineer',
      school: 'Hanyang Univ.',
      email: 'jwsong@imlabworld.com',
    },
    KOR: {
      name: '송진욱',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EC%A7%84%EC%9A%B1_r.png?alt=media&token=ac625d29-fc12-4f6f-baa1-135da394caf0',
      description: 'Software Engineer',
      school: 'Hanyang Univ.',
      email: 'jwsong@imlabworld.com',
    },
  },
  JIMIN: {
    ENG: {
      name: 'Jimin Kim',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%8C%E1%85%B5%E1%84%86%E1%85%B5%E1%86%AB_r.png?alt=media&token=d928d4b1-dc22-4cdd-80c4-d468693c0342',
      description: 'Software Engineer',
      school: '',
      email: 'jmkim@imlabworld.com',
    },
    KOR: {
      name: '김지민',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%8C%E1%85%B5%E1%84%86%E1%85%B5%E1%86%AB_r.png?alt=media&token=d928d4b1-dc22-4cdd-80c4-d468693c0342',
      description: 'Software Engineer',
      school: '',
      email: 'jmkim@imlabworld.com',
    },
  },
  HYOUNGMIN: {
    ENG: {
      name: 'Hyoungmin Lee',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%92%E1%85%A7%E1%86%BC%E1%84%86%E1%85%B5%E1%86%AB_r.png?alt=media&token=03d1871a-b18c-45d1-92b7-fe7442dcdf1b',
      description: 'Software Engineer',
      school: 'Hongik Univ.',
      email: 'hm2@imlabworld.com',
    },
    KOR: {
      name: '이형민',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%92%E1%85%A7%E1%86%BC%E1%84%86%E1%85%B5%E1%86%AB_r.png?alt=media&token=03d1871a-b18c-45d1-92b7-fe7442dcdf1b',
      description: 'Software Engineer',
      school: 'Hongik Univ.',
      email: 'hm2@imlabworld.com',
    },
  },
};

export const MEMBERS_UX = {
  RAESUNG: {
    ENG: {
      name: 'Rae Kang',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EB%9E%98%EC%84%B1_r.png?alt=media&token=88f586b8-bab6-4e58-8695-fb4a996f3f5d',
      description: 'Team Lead / Product Designer',
      school: 'UNIST',
      email: 'raekang@imlabworld.com',
    },
    KOR: {
      name: '강래성',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EB%9E%98%EC%84%B1_r.png?alt=media&token=88f586b8-bab6-4e58-8695-fb4a996f3f5d',
      description: 'Team Lead / Product Designer',
      school: 'UNIST',
      email: 'raekang@imlabworld.com',
    },
  },
  JAEYOON: {
    ENG: {
      name: 'Jaeyoon Jo',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%86%E1%85%AE%E1%84%8C%E1%85%A6-111111111.png?alt=media&token=da5b2915-921a-406c-bc9f-dfe86ad991e3https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%86%E1%85%AE%E1%84%8C%E1%85%A6-111111111.png?alt=media&token=da5b2915-921a-406c-bc9f-dfe86ad991e3',
      description: 'Product Designer',
      school: 'Kookmin Univ.',
      email: 'objet@imlabworld.com',
    },
    KOR: {
      name: '조재윤',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%86%E1%85%AE%E1%84%8C%E1%85%A6-111111111.png?alt=media&token=da5b2915-921a-406c-bc9f-dfe86ad991e3https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%86%E1%85%AE%E1%84%8C%E1%85%A6-111111111.png?alt=media&token=da5b2915-921a-406c-bc9f-dfe86ad991e3',
      description: 'Product Designer',
      school: 'Kookmin Univ.',
      email: 'objet@imlabworld.com',
    },
  },
  GAKYEONG: {
    ENG: {
      name: 'Gakyeong Kim',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%80%E1%85%A1%E1%84%80%E1%85%A7%E1%86%BC_r.png?alt=media&token=ec3554f3-c9be-4102-8122-7c5dd7cbf872',
      description: 'Product Designer',
      school: 'Seoul Tech',
      email: 'kakim@imlabworld.com',
    },
    KOR: {
      name: '김가경',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%80%E1%85%A1%E1%84%80%E1%85%A7%E1%86%BC_r.png?alt=media&token=ec3554f3-c9be-4102-8122-7c5dd7cbf872',
      description: 'Product Designer',
      school: 'Seoul Tech',
      email: 'kakim@imlabworld.com',
    },
  },
};

export const MEMBERS_QA = {
  BYOUNGIN: {
    ENG: {
      name: 'Byoungin Kang',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%87%E1%85%A7%E1%86%BC%E1%84%8B%E1%85%B5%E1%86%AB_r.png?alt=media&token=86a419fe-8b8d-4ee8-8bf2-b6324b6ba655',
      description: 'QA Manager',
      school: 'Sunmoon Univ.',
      email: 'bikang@imlabworld.com',
    },
    KOR: {
      name: '강병인',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%87%E1%85%A7%E1%86%BC%E1%84%8B%E1%85%B5%E1%86%AB_r.png?alt=media&token=86a419fe-8b8d-4ee8-8bf2-b6324b6ba655',
      description: 'QA Manager',
      school: 'Sunmoon Univ.',
      email: 'bikang@imlabworld.com',
    },
  },
};

export const MEMBERS_BIZ = {
  HYUNKI: {
    ENG: {
      name: 'Hyunki Kim',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%92%E1%85%A7%E1%86%AB%E1%84%80%E1%85%B5_r.png?alt=media&token=ef025b3f-5881-41ed-ac4e-f0e05f7d69d4',
      description: 'Team Lead / Business Manager',
      school: 'Konkuk Univ.',
      email: 'hkkim@imlabworld.com',
    },
    KOR: {
      name: '김현기',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%92%E1%85%A7%E1%86%AB%E1%84%80%E1%85%B5_r.png?alt=media&token=ef025b3f-5881-41ed-ac4e-f0e05f7d69d4',
      description: 'Team Lead / Business Manager',
      school: 'Konkuk Univ.',
      email: 'hkkim@imlabworld.com',
    },
  },
  WOOSEOK: {
    ENG: {
      name: 'Wooseok Seol',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EC%9A%B0%EC%84%9D_r.png?alt=media&token=d6780346-b037-4b01-981f-48500a58fdd0',
      description: 'Business Manager',
      school: 'Yonsei Univ.',
      email: 'sws@imlabworld.com',
    },
    KOR: {
      name: '설우석',
      image:
        'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EC%9A%B0%EC%84%9D_r.png?alt=media&token=d6780346-b037-4b01-981f-48500a58fdd0',
      description: 'Business Manager',
      school: 'Yonsei Univ.',
      email: 'sws@imlabworld.com',
    },
  },
};

// export const ABOUT_MEMBERS = {
//   a: {
//     ENG: {
//       description: 'CEO',
//       email: 'yrkwon@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EC%98%88%EB%9E%8C_r.png?alt=media&token=6a5f7f94-2b61-4b92-a23f-a96bf294c79e',
//       name: 'Yeram Kwon',
//       school: 'KAIST GSCT',
//     },
//     KOR: {
//       description: 'CEO',
//       email: 'yrkwon@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EC%98%88%EB%9E%8C_r.png?alt=media&token=6a5f7f94-2b61-4b92-a23f-a96bf294c79e',
//       name: '권예람',
//       school: 'KAIST GSCT',
//     },
//   },
//   b: {
//     ENG: {
//       description: 'CTO / R&D Manager',
//       email: 'hmlee@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%ED%98%95%EB%AC%B5_r.png?alt=media&token=fa0e5406-a5ec-41fa-9c03-dc091378a269',
//       name: 'Hyeongmook Lee',
//       school: 'KAIST',
//     },
//     KOR: {
//       description: 'CTO / R&D Manager',
//       email: 'hmlee@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%ED%98%95%EB%AC%B5_r.png?alt=media&token=fa0e5406-a5ec-41fa-9c03-dc091378a269',
//       name: '이형묵',
//       school: 'KAIST',
//     },
//   },
//   c: {
//     ENG: {
//       description: 'Software Engineer',
//       email: 'csyoo@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EC%B2%A0%EC%8A%B9_r.png?alt=media&token=4123e6b1-fb15-4d92-8308-d83a9758437f',
//       name: 'Chulseung Yoo',
//       school: 'Hanyang Univ.',
//     },
//     KOR: {
//       description: 'Software Engineer',
//       email: 'csyoo@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EC%B2%A0%EC%8A%B9_r.png?alt=media&token=4123e6b1-fb15-4d92-8308-d83a9758437f',
//       name: '유철승',
//       school: 'Hanyang Univ.',
//     },
//   },
//   e: {
//     ENG: {
//       description: 'Product Designer',
//       email: 'raekang@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EB%9E%98%EC%84%B1_r.png?alt=media&token=88f586b8-bab6-4e58-8695-fb4a996f3f5d',
//       name: 'Rae Kang',
//       school: 'UNIST',
//     },
//     KOR: {
//       description: 'Product Designer',
//       email: 'raekang@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EB%9E%98%EC%84%B1_r.png?alt=media&token=88f586b8-bab6-4e58-8695-fb4a996f3f5d',
//       name: '강래성',
//       school: 'UNIST',
//     },
//   },
//   f: {
//     ENG: {
//       description: 'Project Lead / Data Scientist',
//       email: 'jaram.park@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EB%AF%BC%EC%88%98_r.png?alt=media&token=6c58146f-33aa-4de7-8c23-0e21a1249b44',
//       name: 'Jaram Park',
//       school: 'KAIST',
//     },
//     KOR: {
//       description: 'Project Lead / Data Scientist',
//       email: 'jaram.park@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EB%AF%BC%EC%88%98_r.png?alt=media&token=6c58146f-33aa-4de7-8c23-0e21a1249b44',
//       name: '박자람',
//       school: 'KAIST',
//     },
//   },
//   i: {
//     ENG: {
//       description: 'Business Manager',
//       email: 'sws@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EC%9A%B0%EC%84%9D_r.png?alt=media&token=d6780346-b037-4b01-981f-48500a58fdd0',
//       name: 'Wooseok Seol',
//       school: 'Yonsei Univ.',
//     },
//     KOR: {
//       description: 'Business Manager',
//       email: 'sws@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EC%9A%B0%EC%84%9D_r.png?alt=media&token=d6780346-b037-4b01-981f-48500a58fdd0',
//       name: '설우석',
//       school: 'Yonsei Univ.',
//     },
//   },
//   n: {
//     ENG: {
//       description: 'Business Manager',
//       email: 'powerlgc@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EA%B8%B0%EC%B2%A0_r.png?alt=media&token=01ce1a3c-838d-4610-b1e0-548d6b93c561',
//       name: 'Gicheol Lee',
//       school: 'Hanyang Univ.',
//     },
//     KOR: {
//       description: 'Business Manager',
//       email: 'powerlgc@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EA%B8%B0%EC%B2%A0_r.png?alt=media&token=01ce1a3c-838d-4610-b1e0-548d6b93c561',
//       name: '이기철',
//       school: 'Hanyang Univ.',
//     },
//   },
//   o: {
//     ENG: {
//       description: 'Product Designer',
//       email: 'objet@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%8C%E1%85%A2%E1%84%8B%E1%85%B2%E1%86%AB_r.JPG?alt=media&token=8d5b7a8f-8436-414c-b643-3e0e3e423628',
//       name: 'Jaeyoon Jo',
//       school: 'Kookmin Univ.',
//     },
//     KOR: {
//       description: 'Product Designer',
//       email: 'objet@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%8C%E1%85%A2%E1%84%8B%E1%85%B2%E1%86%AB_r.JPG?alt=media&token=8d5b7a8f-8436-414c-b643-3e0e3e423628',
//       name: '조재윤',
//       school: 'Kookmin Univ.',
//     },
//   },
//   p: {
//     ENG: {
//       description: 'Software Engineer',
//       email: 'hcjung@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%92%E1%85%A7%E1%86%BC%E1%84%8E%E1%85%A1%E1%86%AB_r.png?alt=media&token=036f1ce6-b00c-4cfb-8bd0-79c3fe6755bf',
//       name: 'Hyungchan Jung',
//       school: 'Yonsei Univ.',
//     },
//     KOR: {
//       description: 'Software Engineer',
//       email: 'hcjung@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%92%E1%85%A7%E1%86%BC%E1%84%8E%E1%85%A1%E1%86%AB_r.png?alt=media&token=036f1ce6-b00c-4cfb-8bd0-79c3fe6755bf',
//       name: '정형찬',
//       school: 'Yonsei Univ.',
//     },
//   },
//   q: {
//     ENG: {
//       description: 'QA Manager',
//       email: 'bikang@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%87%E1%85%A7%E1%86%BC%E1%84%8B%E1%85%B5%E1%86%AB_r.png?alt=media&token=86a419fe-8b8d-4ee8-8bf2-b6324b6ba655',
//       name: 'Byoungin Kang',
//       school: 'Sunmoon Univ.',
//     },
//     KOR: {
//       description: 'QA Manager',
//       email: 'bikang@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%E1%84%87%E1%85%A7%E1%86%BC%E1%84%8B%E1%85%B5%E1%86%AB_r.png?alt=media&token=86a419fe-8b8d-4ee8-8bf2-b6324b6ba655',
//       name: '강병인',
//       school: 'Sunmoon Univ.',
//     },
//   },
//   s: {
//     ENG: {
//       description: 'Software Engineer',
//       email: 'jwsong@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EC%A7%84%EC%9A%B1_r.png?alt=media&token=ac625d29-fc12-4f6f-baa1-135da394caf0',
//       name: 'Jinwook Song',
//       school: 'Hanyang Univ.',
//     },
//     KOR: {
//       description: 'Software Engineer',
//       email: 'jwsong@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%EC%A7%84%EC%9A%B1_r.png?alt=media&token=ac625d29-fc12-4f6f-baa1-135da394caf0',
//       name: '송진욱',
//       school: 'Hanyang Univ.',
//     },
//   },
//   r: {
//     ENG: {
//       description: 'Hardware Engineer',
//       email: 'junning@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%ED%98%95%EC%A4%80_r.png?alt=media&token=615a2310-1af0-4ece-bf59-f8b4f0bcddb1',
//       name: 'Hyeongjun Jeon',
//       school: 'Gachon Univ.',
//     },
//     KOR: {
//       description: 'Hardware Engineer',
//       email: 'junning@imlabworld.com',
//       image:
//         'https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/about%2F%ED%98%95%EC%A4%80_r.png?alt=media&token=615a2310-1af0-4ece-bf59-f8b4f0bcddb1',
//       name: '전형준',
//       school: 'Gachon Univ.',
//     },
//   },
// };

export const ABOUT_ADVISER = {
  '1': {
    ENG: {
      description: 'Business Advisor/Medical Scientist',
      name: 'Jihoon Jeong',
      school: 'Kyung Hee Cyber Univ. Professor',
    },
    KOR: {
      description: 'Business Advisor/Medical Scientist',
      name: '정지훈',
      school: 'Kyung Hee Cyber Univ. Professor',
    },
  },
  '2': {
    ENG: {
      description: 'Business Advisor',
      name: 'Wonjoon Kim',
      school: 'KAIST Professor',
    },
    KOR: {
      description: 'Business Advisor',
      name: '김원준',
      school: 'KAIST Professor',
    },
  },
  '3': {
    ENG: {
      name: 'Keun-jeong Song',
      description: 'Medical Doctor',
      school: 'Samsung Medical Center',
    },
    KOR: {
      name: '송근정',
      description: 'Medical Doctor',
      school: 'Samsung Medical Center',
    },
  },
};
