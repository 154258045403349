const newDateChange = (year, month, day, language) => {
  const strYear = year + (language === "ko" ? "년 " : "/");
  const strMon =
    (String(month).length === 1 ? "0" + month : month) +
    (language === "ko" ? "월 " : "/");
  const strDay =
    (String(day).length === 1 ? "0" + day : day) +
    (language === "ko" ? "일" : "");
  return strYear + strMon + strDay;
};

export const NEWS_CONTENTS = [
  {
    KOR: {
      name:
        "[전자신문]글로벌 K스타트업, 세계 무대 위해 아이엠랩, 엔씽 등 5개 기업 선정",
      url: "http://www.etnews.com/201310280389",
      description: newDateChange(2013, 10, 28, "ko")
    },
    ENG: {
      name:
        " [Electronic Times] Global K-Startup, selects 5 companies, I.M.LAB, Nthing, etc, for global stage",
      url: "http://www.etnews.com/201310280389",
      description: newDateChange(2013, 10, 28, "en")
    }
  },
  {
    KOR: {
      name: "[EBS]구글 에릭슈미트 회장과의 대화",
      url: "https://www.youtube.com/watch?v=IbDyU1y-AUI",
      description: newDateChange(2013, 11, 17, "ko")
    },
    ENG: {
      name: "[EBS] Talking with Eric Schmidt, executive chairman of Google",
      url: "https://www.youtube.com/watch?v=IbDyU1y-AUI",
      description: newDateChange(2013, 11, 17, "en")
    }
  },
  {
    KOR: {
      name: "[청년의사]소방관도 하는 CPR교육, 체육교사도 할 수 있다?",
      url:
        "http://kr.aving.net/news/view.php?articleId=1197490&Branch_ID=kr&rssid=naver&mn_name=news",
      description: newDateChange(2014, 9, 29, "ko")
    },
    ENG: {
      name:
        " [Youth Doctor] CPR training by fire fighters, PE teachers can do as well?",
      url:
        "http://kr.aving.net/news/view.php?articleId=1197490&Branch_ID=kr&rssid=naver&mn_name=news",
      description: newDateChange(2014, 9, 29, "en")
    }
  },
  {
    KOR: {
      name:
        "[에이빙][Health IT Show] 아이엠랩, 증강현실 기술 접목한 심폐소생술 시뮬레이션 교육 솔루션 '하티센스' 전시",
      url:
        "http://kr.aving.net/news/view.php?articleId=1197490&Branch_ID=kr&rssid=naver&mn_name=news",
      description: newDateChange(2014, 10, 27, "ko")
    },
    ENG: {
      name:
        "[Aving][Health IT Show] I.M.LAB, exhibits augmented reality technology integrated CPR simulation training solution ‘HeartiSense’",
      url:
        "http://kr.aving.net/news/view.php?articleId=1197490&Branch_ID=kr&rssid=naver&mn_name=news",
      description: newDateChange(2014, 10, 27, "en")
    }
  },
  {
    KOR: {
      name:
        "[Arirang Prime]Arirang Prime Ep241 ‘IOT, 세상을 바꾸는 마술’ (한국의 젊은 대학생들은 IoT기술을 발달 시켜 나가고 있다! )",
      url: "http://youtu.be/OEwLfrMGX5s",
      description: newDateChange(2014, 10, 29, "ko")
    },
    ENG: {
      name:
        "[Arirang Prime] Arirang Prime EP 241, ‘IOT, the magic that changes the world’ (Korea’s young college students are advancing IoT technology!)",
      url: "http://youtu.be/OEwLfrMGX5s",
      description: newDateChange(2014, 10, 29, "en")
    }
  },
  {
    KOR: {
      name: "[서울신문]심폐소생술 연습 키트 등 아이디어 '톡톡'",
      url: "http://www.seoul.co.kr/news/newsView.php?id=20150202017016",
      description: newDateChange(2015, 2, 2, "ko")
    },
    ENG: {
      name: "[Seoul Newspaper] CPR training kit and other ideas",
      url: "http://www.seoul.co.kr/news/newsView.php?id=20150202017016",
      description: newDateChange(2015, 2, 2, "en")
    }
  },
  {
    KOR: {
      name: "[CNB News]119 안전재단 협약식",
      url: "http://www.cnbnews.com/news/article.html?no=304810",
      description: newDateChange(2015, 9, 15, "ko")
    },
    ENG: {
      name: "[CNB News] 119 Safety Foundation agreement ceremony",
      url: "http://www.cnbnews.com/news/article.html?no=304810",
      description: newDateChange(2015, 9, 15, "en")
    }
  },
  {
    KOR: {
      name:
        "[HeartiSense Facebook Page]HeartiSense's booth at the EMS Worldshow Las Vegas",
      url:
        "https://www.facebook.com/heartisense.cprcube/photos/a.589747714506270.1073741828.561796170634758/589791641168544/?type=3&theater",
      description: newDateChange(2015, 9, 25, "ko")
    },
    ENG: {
      name:
        "[HeartiSense Facebook Page]HeartiSense's booth at the EMS Worldshow Las Vegas",
      url:
        "https://www.facebook.com/heartisense.cprcube/photos/a.589747714506270.1073741828.561796170634758/589791641168544/?type=3&theater",
      description: newDateChange(2015, 9, 25, "en")
    }
  },
  {
    KOR: {
      name: "[시디즈 블로그]생명을 살리는 기술, 아이엠랩",
      url: "http://blog.naver.com/chairblues/220508377906",
      description: newDateChange(2015, 10, 14, "ko")
    },
    ENG: {
      name: "[SIDIZ Blog] The technology that saves lives, I.M.LAB.",
      url: "http://blog.naver.com/chairblues/220508377906",
      description: newDateChange(2015, 10, 14, "en")
    }
  },
  {
    KOR: {
      name:
        "[HeartiSense Facebook Page]HeartiSense's booth at the 2015 ECCU Conference in San Diego",
      url:
        "https://www.facebook.com/heartisense.cprcube/photos/a.589747714506270.1073741828.561796170634758/589792331168475/?type=3&theater",
      description: newDateChange(2015, 12, 8, "ko")
    },
    ENG: {
      name:
        "[HeartiSense Facebook Page]HeartiSense's booth at the 2015 ECCU Conference in San Diego",
      url:
        "https://www.facebook.com/heartisense.cprcube/photos/a.589747714506270.1073741828.561796170634758/589792331168475/?type=3&theater",
      description: newDateChange(2015, 12, 8, "en")
    }
  },
  {
    KOR: {
      name: "[EMS WORLD]HeartiSense: A New Experience in CPR Training",
      url:
        "http://www.emsworld.com/video/12149751/heartisense-a-new-experience-in-cpr-training",
      description: newDateChange(2015, 12, 15, "ko")
    },
    ENG: {
      name: "[EMS WORLD]HeartiSense: A New Experience in CPR Training",
      url:
        "http://www.emsworld.com/video/12149751/heartisense-a-new-experience-in-cpr-training",
      description: newDateChange(2015, 12, 15, "en")
    }
  },
  {
    KOR: {
      name:
        "[HeartiSense Facebook Page]Korean Redcross' National CPR competition for middle-high school student with HeartiSense.",
      url: "https://www.facebook.com/heartisense.cprcube/posts/589797281167980",
      description: newDateChange(2015, 12, 19, "ko")
    },
    ENG: {
      name:
        "[HeartiSense Facebook Page]Korean Redcross' National CPR competition for middle-high school student with HeartiSense.",
      url: "https://www.facebook.com/heartisense.cprcube/posts/589797281167980",
      description: newDateChange(2015, 12, 19, "en")
    }
  },
  {
    KOR: {
      name:
        "[HeartiSense Facebook Page]HeartiSense at the NZRC 2016 in New Zealand with iSimulate",
      url: "https://www.facebook.com/heartisense.cprcube/posts/640643129416728",
      description: newDateChange(2016, 4, 7, "ko")
    },
    ENG: {
      name:
        "[HeartiSense Facebook Page]HeartiSense at the NZRC 2016 in New Zealand with iSimulate",
      url: "https://www.facebook.com/heartisense.cprcube/posts/640643129416728",
      description: newDateChange(2016, 4, 7, "end")
    }
  },
  {
    KOR: {
      name:
        "[Google officail Blog]Celebrating and advancing entrepreneurship around the world",
      url:
        "https://googleblog.blogspot.kr/2016/06/global-entrepreneurship-summit.html",
      description: newDateChange(2016, 6, 23, "ko")
    },
    ENG: {
      name:
        "[Google officail Blog]Celebrating and advancing entrepreneurship around the world",
      url:
        "https://googleblog.blogspot.kr/2016/06/global-entrepreneurship-summit.html",
      description: newDateChange(2016, 6, 23, "en")
    }
  },
  {
    KOR: {
      name: "[TheNewYorkTimes]BRITAIN EU OBAMA 17",
      url: "https://www.nytsyn.com/images/photos/1410153.html",
      description: newDateChange(2016, 6, 24, "ko")
    },
    ENG: {
      name: "[TheNewYorkTimes]BRITAIN EU OBAMA 17",
      url: "https://www.nytsyn.com/images/photos/1410153.html",
      description: newDateChange(2016, 6, 24, "en")
    }
  },
  {
    KOR: {
      name:
        '[동아일보]국내 스타트업, 구글 포털 통해 오바마와 대화…“창의적 아이디어"',
      url:
        "http://news.naver.com/main/read.nhn?mode=LSD&mid=sec&oid=020&aid=0002983248&sid1=001",
      description: newDateChange(2016, 6, 26, "ko")
    },
    ENG: {
      name:
        "[The Dong-A Ilbo] Korean Startup, talks with the president Obama through Google portal…”Creative Idea”",
      url:
        "http://news.naver.com/main/read.nhn?mode=LSD&mid=sec&oid=020&aid=0002983248&sid1=001",
      description: newDateChange(2016, 6, 26, "en")
    }
  },
  {
    KOR: {
      name: "[한국경제]한·미 대통령, 창업 지원에 대한 열정은 똑같아요",
      url: "http://www.hankyung.com/news/app/newsview.php?aid=2016062636661",
      description: newDateChange(2016, 6, 26, "ko")
    },
    ENG: {
      name:
        "[Hankyung] The president of Korea and US, share the same passion for entrepreneurship support",
      url:
        "http://news.naver.com/main/read.nhn?mode=LSD&mid=sec&oid=020&aid=0002983248&sid1=001",
      description: newDateChange(2016, 6, 26, "en")
    }
  },
  {
    KOR: {
      name: "[HelloDD]오바마도 감동, 韓 기업의 사람 살리는 기술",
      url: "http://www.hankyung.com/news/app/newsview.php?aid=2016062636661",
      description: newDateChange(2016, 7, 19, "ko")
    },
    ENG: {
      name:
        "[HelloDD] The president Obama is impressed, Korean company’s life saving technology",
      url: "http://www.hankyung.com/news/app/newsview.php?aid=2016062636661",
      description: newDateChange(2016, 7, 19, "en")
    }
  },
  {
    KOR: {
      name:
        "[EMS World] HeartiSense - Winner of 2016 EMS World Innovation Awards",
      url:
        "https://www.emsworld.com/article/12283073/2016-ems-world-innovation-awards",
      description: newDateChange(2016, 11, 29, "ko")
    },
    ENG: {
      name:
        "[EMS World] HeartiSense - Winner of 2016 EMS World Innovation Awards",
      url:
        "https://www.emsworld.com/article/12283073/2016-ems-world-innovation-awards",
      description: newDateChange(2016, 11, 29, "en")
    }
  },
  {
    KOR: {
      name:
        "[EMS World] cprCUBE - EMS World Innovation Awards 2017: Meet the Finalists",
      url:
        "https://www.emsworld.com/article/218777/ems-world-innovation-awards-2017-meet-finalists",
      description: newDateChange(2017, 10, 2, "ko")
    },
    ENG: {
      name:
        "[EMS World] cprCUBE - EMS World Innovation Awards 2017: Meet the Finalists",
      url:
        "https://www.emsworld.com/article/218777/ems-world-innovation-awards-2017-meet-finalists",
      description: newDateChange(2016, 10, 2, "en")
    }
  },
  {
    KOR: {
      name:
        "[EMS World] CPR Add-on Kit - The 2019 EMS World Innovation Award Winners",
      url:
        "https://www.emsworld.com/article/1223573/2019-ems-world-innovation-award-winners",
      description: newDateChange(2019, 11, 29, "ko")
    },
    ENG: {
      name:
        "[EMS World] CPR Add-on Kit - The 2019 EMS World Innovation Award Winners",
      url:
        "https://www.emsworld.com/article/1223573/2019-ems-world-innovation-award-winners",
      description: newDateChange(2019, 11, 29, "en")
    }
  }
];
