/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { DotLessUl } from "assets/Common/CSS";
import { selectLanguage, pageInformations } from "assets/Common/ContextAPI";

const MobileMenu = ({
  subscribeLanguage,
  subscribeMenuStatus,
  menuVisibleStatus
}) => {
  const PAGE_INFO = useContext(pageInformations);
  const language = useContext(selectLanguage);

  return (
    <MobileMenuContainerDiv menuVisibleStatus={menuVisibleStatus}>
      <DotLessUl>
        {Object.keys(PAGE_INFO)
          .slice(0, -2)
          .map(menu => {
            const snnipet = PAGE_INFO[menu][language];
            return (
              <MobileMenuContentNavLink
                key={menu}
                exact
                activeStyle={{ color: "black", fontWeight: "bold" }}
                to={snnipet.url}
                onClick={subscribeMenuStatus.bind(null, false)}
              >
                <MobileMenuWrapperLi>
                  {PAGE_INFO[menu].name.replace("-", " ").toUpperCase()}
                </MobileMenuWrapperLi>
              </MobileMenuContentNavLink>
            );
          })}
        <MobileMenuLanguageSelectWarpperLi
          style={{ color: "white" }}
          onClick={() => {
            subscribeMenuStatus(null, false);
            subscribeLanguage(language === "KOR" ? "ENG" : "KOR");
          }}
        >
          {language === "KOR" ? "ENGLISH" : "KOREAN"}
        </MobileMenuLanguageSelectWarpperLi>
      </DotLessUl>
    </MobileMenuContainerDiv>
  );
};

//Styled Css
const MobileMenuContainerDiv = styled.div`
  position: absolute;
  text-align: right;
  right: 0.5px;
  top: 100px;
  width: 85vw;
  visibility: ${props =>
    props.menuVisibleStatus === true || props.languageSelect
      ? "visible"
      : "hidden"};
  z-index: 200;
  margin-left: 10vw;
  margin-right: 6vw;
  background-color: #0066cc;
`;

const MobileMenuWrapperLi = styled.li`
  cursor: pointer;
  margin-top: 8px;
  padding: 0.8vw;
  padding-right: 2vw;
`;

const MobileMenuLanguageSelectWarpperLi = styled.li`
  cursor: pointer;
  padding-right: 2vw;
  padding-bottom: 2vw;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
  font-family: NanumGothic;
  text-rendering: optimizeSpeed;
`;

const MobileMenuContentNavLink = styled(NavLink)`
  text-decoration: none;
  text-rendering: optimizeSpeed;
  letter-spacing: 0.3px;
  font-family: NanumGothic;
  font-size: 13px;
  font-weight: 500;
  color: white;
`;

export default MobileMenu;
