import React from "react";
import styled from "styled-components";
import { newBrowserOpener } from "assets/Common/Functions";

const NewsContent = ({ name, url, date }) => {
  return (
    <>
      <NewsContentsNameP onClick={newBrowserOpener.bind(null, url)}>
        {name}
      </NewsContentsNameP>
      <NewsContentsDateP>{date}</NewsContentsDateP>
    </>
  );
};

const NewsContentsNameP = styled.p`
  cursor: pointer;
  font-size: 20px;
  font-family: OpenSans;
  margin-top: 30px;
  margin-bottom: 2px;
  &:hover {
    color: #0066cc;
  }
  &:active {
    font-weight: bold;
  }
  @media screen and (max-width: 1024px) {
    font-size: 2.5vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 4vw;
  }
`;

const NewsContentsDateP = styled.p`
  font-family: OpenSans;
  color: #848484;
  margin-top: 3px;
  @media screen and (max-width: 1024px) {
    font-size: 2.5vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 4vw;
  }
`;

export default NewsContent;
