import React from "react";
import styled from "styled-components";
import Map from "./Map";
import DetailContactInfo from "./DetailContactInfo";
import { CONTACT_TITLE, CONTACT_INFO } from "assets/Common/RenderData/Contact";

const ContactInfo = ({ language }) => (
  <ContactInfoContainerDiv>
    <ContactInfoTitleH1>{CONTACT_TITLE.title[language]}</ContactInfoTitleH1>
    <Map language={language} />
    <DetailContactInfo contactInfo={CONTACT_INFO[language]} />
  </ContactInfoContainerDiv>
);

//Styled CSS

const ContactInfoContainerDiv = styled.div`
  /*border: 3px solid black;*/
  font-family: NanumGothic, sans-serif;
  letter-spacing: 0.5px;
  width: 100%;
  position: relative;
  @media screen and (max-width: 1024px) {
    margin-top: 10vw;
  }
`;

const ContactInfoTitleH1 = styled.h1`
  font-family: MontserratRegular, sans-serif;
  font-size: 35px;
  letter-spacing: 1px;
  font-weight: 400;
  @media screen and (max-width: 1600px) {
    font-size: 30px;
  }
  @media screen and (max-width: 1024px) {
    text-align: center;
    font-size: 3.4vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 6vw;
  }
`;

export default ContactInfo;
