import React from "react";
import styled from "styled-components";
import { newBrowserOpener } from "assets/Common/Functions";

const ServiceContentLeft = ({
  logo,
  url,
  image,
  description,
  language,
  isAok
}) => (
  <>
    <LeftProductImg src={image} alt="제품이미지" />
    <LeftProductLogoImg isAok={isAok} src={logo} alt="제품로고" />
    <DescriptionWrapperDiv isAok={isAok}>
      <LeftProductDescriptionP>{description}</LeftProductDescriptionP>
      {url ? (
        <SelectableP onClick={newBrowserOpener.bind(null, url)}>
          {language === "KOR" ? "더 보기" : "MORE"} >
        </SelectableP>
      ) : null}
    </DescriptionWrapperDiv>
  </>
);

const DescriptionWrapperDiv = styled.div`
  position: absolute;
  top: ${props => (props.isAok ? "23%" : "40%")};
  left: 53%;

  width: 45%;
  @media screen and (max-width: 600px) {
    position: static;
    width: 100%;
  }
`;

const LeftProductDescriptionP = styled.p`
  word-break: keep-all;
  font-size: 1vw;
  text-align: left;
  @media screen and (max-width: 1600px) {
    line-height: 2vw;
    font-size: 1.6vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 3.8vw;
    word-break: break-all;
    text-align: justify;
    line-height: 6vw;
  }
`;

const LeftProductLogoImg = styled.img`
  position: absolute;
  width: 15vw;
  top: ${props => (props.isAok ? "10%" : "20%")};
  left: 53%;
  @media screen and (max-width: 1024px) {
    /*border: 3px solid red;*/
  }
  @media screen and (max-width: 600px) {
    /*border: 3px solid red;*/
    position: static;
    width: 50vw;
    margin-top: 10px;
  }
`;

const LeftProductImg = styled.img`
  position: absolute;
  top: 10%;
  left: 0;
  width: 50%;
  @media screen and (max-width: 1024px) {
    /*border: 3px solid red;*/
  }
  @media screen and (max-width: 600px) {
    /*border: 3px solid red;*/
    position: static;
    width: 100%;
    right: 0vw;
    top: 0;
  }
`;

const SelectableP = styled.p`
  cursor: pointer;
  margin-top: 3vw;
  padding-left: 0.5vw;
  text-align: right;
  font-family: NanumGothic, sans-serif;
  font-size: 0.9vw;
  color: #0066cc;
  @media screen and (max-width: 1600px) {
    font-size: 1.5vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 3.4vw;
  }
`;

export default ServiceContentLeft;
