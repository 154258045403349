import React, { useContext } from "react";
import styled from "styled-components";
import { selectLanguage } from "../../assets/Common/ContextAPI";
import RecruitTop from "./RecruitTop/RecruitTop";
import RecruitMid from "./RecruitMid/RecruitMid";
import RecruitBottom from "./RecruidBottom/RecruitBottom";

const Recruit = ({ subscribePage, location }) => {
  subscribePage(location);
  const language = useContext(selectLanguage);

  return (
    <RecruitAllPageContainerDiv>
      <RecruitTop language={language} />
      <hr />
      <RecruitMid language={language} />
      <RecruitBottom language={language} />
    </RecruitAllPageContainerDiv>
  );
};

const RecruitAllPageContainerDiv = styled.div`
  /*border: 3px solid black;*/
  text-align: center;
  margin: auto 22vw 8vw 22vw;
  @media screen and (max-width: 1500px) {
    margin: auto 16vw 8vw 16vw;
  }
  @media screen and (max-width: 1130px) {
    margin: 6vw 10vw 8vw 10vw;
  }
  @media screen and (max-width: 1130px) {
    margin: 12vw 5vw 10vw 5vw;
  }
`;

export default Recruit;
