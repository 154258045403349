/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";

const HeaderImg = ({ menuInfo }) => {
  return (
    <>
      {menuInfo.url ? (
        <>
          <HeaderImgWrapperDiv
            menuInfo={menuInfo.name}
            image={menuInfo.image}
          />
          <HeaderImgIEFilterDiv />
        </>
      ) : null}
    </>
  );
};

//styled CSS
const HeaderImgWrapperDiv = styled.div`
  /*border: 3px solid blue;*/
  position: relative;
  left: ${props =>
    props.menuInfo === "홈" || props.menuInfo === "home" ? "0" : "0"};
  max-width: ${props =>
    props.menuInfo === "홈" || props.menuInfo === "home" ? "100%" : "100%"};
  height: 100%;

  @media screen and (max-width: 1024px) {
    left: 0;
    max-width: 100%;
  }

  /*This is for image pre-render*/
  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(${props => props.image});
    background-size: 100% 100%;
    content: "";
    filter: ${props => {
      const { menuInfo } = props;
      return menuInfo === "홈" || menuInfo === "home"
        ? "none"
        : "brightness(0.65)";
    }};
  }
`;

//This CSS only for IE(Internet Explorer) browser, because of IE did not suppoet filter property.
const HeaderImgIEFilterDiv = styled.div`
  display: none;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
    top: 0;
    display: block;
    background-color: rgba(1, 1, 1, 0.5);
  }
`;

export default HeaderImg;
