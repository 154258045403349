import React from "react";
import styled from "styled-components";

const ValueContent = ({ name, image, description }) => {
  return (
    <>
      <ValueContentImg src={image} alt={name} />
      <ValueContentWrapperDiv>
        <ValueContentTitleH3>{name}</ValueContentTitleH3>
        <ValueContentDescriptionP>{description}</ValueContentDescriptionP>
      </ValueContentWrapperDiv>
    </>
  );
};

//Styled CSS
const ValueContentImg = styled.img`
  width: 100px;
  height: 100px;
  @media screen and (max-width: 600px) {
    height: 30%;
    width: auto;
  }
`;

const ValueContentWrapperDiv = styled.div`
  position: relative;
  bottom: 1.5vw;
  color: #0066cc;
`;

const ValueContentTitleH3 = styled.h3`
  font-family: MontserratRegular, sans-serif;
  word-break: keep-all;
  font-size: 1.5vw;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: 3vw;
  @media screen and (max-width: 1600px) {
    font-size: 1.7vw;
  }
  @media screen and (max-width: 1024px) {
    font-size: 2.5vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 6vw;
  }
`;

const ValueContentDescriptionP = styled.p`
  font-size: 20px;
  word-break: keep-all;
  @media screen and (max-width: 1600px) {
    line-height: 30px;
    font-size: 18px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 2.5vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 4.5vw;
  }
`;

export default ValueContent;
