import React from "react";
import styled from "styled-components";
import { newLineMaker } from "assets/Common/Functions";

const RecruitBottomContent = ({ title, contentList }) => {
  const contents = Object.keys(contentList);
  return (
    <>
      <RecruitBottomContentTitleP>{title}</RecruitBottomContentTitleP>
      {contents.map(content => (
        <React.Fragment key={content}>
          <RecruitBottomDescriptionTitleP>
            {contentList[content].name}
          </RecruitBottomDescriptionTitleP>
          <RecruitBottomDescriptionContainerDiv title={title}>
            {newLineMaker(contentList[content].description, "8px")}
          </RecruitBottomDescriptionContainerDiv>
        </React.Fragment>
      ))}
    </>
  );
};

const RecruitBottomContentTitleP = styled.p`
  margin-bottom: 0;
  font-size: 1.6vw;
  @media screen and (max-width: 1500px) {
    font-size: 2vw;
  }
  @media screen and (max-width: 1130px) {
    font-size: 3vw;
  }
  @media screen and (max-width: 700px) {
    margin-top: 10vw;
    font-size: 5vw;
  }
  @media screen and (max-width: 500px) {
    font-size: 6vw;
  }
`;

const RecruitBottomDescriptionTitleP = styled.p`
  font-size: 1.2vw;
  font-weight: bold;
  color: #848484;
  margin-bottom: vw;
  @media screen and (max-width: 1500px) {
    font-size: 1.8vw;
  }
  @media screen and (max-width: 1130px) {
    font-size: 2.4vw;
  }
  @media screen and (max-width: 700px) {
    text-align: left;
    font-size: 3.2vw;
  }
  @media screen and (max-width: 500px) {
    font-size: 4.3vw;
  }
`;

const RecruitBottomDescriptionContainerDiv = styled.div`
  /*border: 1px solid yellow;  */
  position: relative;
  word-break: keep-all;
  margin: ${props =>
      props.title.includes("지원") || props.title.includes("APPLY")
        ? "1vw"
        : "1.5vw"}
    8vw 1vw 8vw;
  bottom: 0.6vw;
  font-size: 0.8vw;
  line-height: 1.3vw;
  color: ${props =>
    props.title.includes("지원") || props.title.includes("APPLY")
      ? "#0066cc"
      : "#848484"};

  @media screen and (max-width: 1500px) {
    line-height: 2vw;
    font-size: 1.2vw;
  }
  @media screen and (max-width: 1130px) {
    font-size: 1.6vw;
  }
  @media screen and (max-width: 700px) {
    margin: ${props =>
        props.title.includes("지원") || props.title.includes("APPLY")
          ? "4.8vw"
          : "5.5vw"}
      0 1vw 0;
    bottom: 3vw;
    font-size: 3vw;
    text-align: left;
    line-height: 3.8vw;
  }
  @media screen and (max-width: 500px) {
    line-height: 5vw;
    font-size: 4vw;
  }
`;

export default RecruitBottomContent;
