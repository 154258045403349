import React, { createElement } from "react";
import styled from "styled-components";
import useForm from "react-hook-form";
import { FEEDBACK_INFO, FEEDBACK_TITLE } from "assets/Common/RenderData/Contact";

// eslint-disable-next-line react/prop-types
const FeedbackForm = ({ language }) => {
  const { register, handleSubmit } = useForm();
  const formContents = Object.keys(FEEDBACK_INFO[language]);
  //이메일 전송 전 필수사항에 따른 메시지 출력 분기
  const onSubmit = (value, e) => {
    //필수 사항 미기재 체크
    const NotWrittenEssential = value => {
      const checkNotWrittenEssential = [];
      Object.keys(value)
        .slice(0, -3)
        .map(curr => (!value[curr] ? checkNotWrittenEssential.push(FEEDBACK_INFO[language][curr].title) : null));
      return checkNotWrittenEssential;
    };

    //Firebase function 에 보낼 객체 데이터 생성
    const makeSendEmailData = data => {
      const emailData = {};
      Object.keys(data).forEach(curr => (emailData[FEEDBACK_INFO.ENG[curr].title.toLowerCase()] = data[curr]));
      return emailData;
    };

    //필수사항이 입력되지 않았을 경우
    if (NotWrittenEssential(value).length > 0) {
      let alertMsg;
      if (NotWrittenEssential(value).length === 2) {
        language === "KOR" ? (alertMsg = "필수입력사항을 기재해주세요") : (alertMsg = "Please Check the Essential");
      }
      //email || name 두 가지 중 한가지만 입력되었을 경우
      else {
        language === "KOR"
          ? (alertMsg = NotWrittenEssential(value).toString() + "을 입력해주세요")
          : (alertMsg = "Please Check the " + NotWrittenEssential(value).toString());
      }
      setTimeout(() => alert(alertMsg), 500);
    }
    // 필수사항이 모두 입력되었을 경우
    else {
      value = makeSendEmailData(value);

      fetch(process.env.REACT_APP_EMAIL_ADDRESS, {
        method: "POST",
        body: JSON.stringify(value),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        }
      })
        .then(res => {
          let alertMsg;
          if (res.status === 200) {
            alertMsg = language === "KOR" ? "메일을 성공적으로 전달했습니다." : "Thanks for your feedback.";
          } else {
            alertMsg = language === "KOR" ? "오류로 인해 메일 전달에 실패 했습니다." : "Send fail";
          }
          alert(alertMsg);
          e.target.reset();
        })
        .catch(e => console.log("error catch :" + e));
    }
  };

  return (
    <FeedBackPageContainerDiv>
      <FeedBackPageTitleH1>{FEEDBACK_TITLE.title[language]}</FeedBackPageTitleH1>
      <form onSubmit={handleSubmit(onSubmit)}>
        {formContents.map((form, index) => {
          const snnipet = FEEDBACK_INFO[language][form];
          return (
            <React.Fragment key={index}>
              <FeedBackFormTitleH3>
                {snnipet.title}
                {snnipet.essential ? (language === "KOR" ? " (필수)" : " (required)") : null}
              </FeedBackFormTitleH3>
              {createElement(snnipet.type === "input" ? FeedBackFormInput : FeedBackFormTextArea, {
                key: form,
                name: form,
                ref: register
              })}
            </React.Fragment>
          );
        })}
        <FeedBackFormButton>{language === "KOR" ? "보내기" : "SEND"}</FeedBackFormButton>
      </form>
    </FeedBackPageContainerDiv>
  );
};

const FeedBackPageContainerDiv = styled.div`
  width: 100%;
`;

const FeedBackPageTitleH1 = styled.h1`
  font-family: MontserratRegular, sans-serif;
  font-size: 35px;
  letter-spacing: 1px;
  font-weight: 400;
  @media screen and (max-width: 1600px) {
    font-size: 30px;
  }
  @media screen and (max-width: 1024px) {
    text-align: center;
    font-size: 3.4vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 6vw;
  }
`;

const FeedBackFormTitleH3 = styled.h3`
  font-family: NanumGothic, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #848484;
  margin-bottom: 4px;
  @media screen and (max-width: 1600px) {
    font-size: 15px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 1.8vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 3.5vw;
  }
`;

const FeedBackFormInput = styled.input`
  font-family: OpenSans, sans-serif;
  font-size: 18px;
  width: 32vw;
  height: 1.5vw;
  margin-bottom: 0.2vw;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.1) inset;

  @media screen and (max-width: 1024px) {
    width: 100%;
    font-size: 2vw;
    height: 4vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 3vw;
    height: 5.5vw;
  }
`;

const FeedBackFormTextArea = styled.textarea`
  font-family: OpenSans, sans-serif;
  font-size: 15px;
  width: 32vw;
  height: 9vw;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.1) inset;
  resize: none;
  @media screen and (max-width: 1024px) {
    width: 100%;
    font-size: 2vw;
    height: 20vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 3vw;
  }
`;

const FeedBackFormButton = styled.button`
  cursor: pointer;
  margin-top: 25px;
  font-family: MontserratBold, sans-serif;
  font-size: 15px;
  color: #fff;
  background-color: #0677b4;
  width: 50%;
  height: 50px;
  outline: 0;
  border: 0;
  border-radius: 2px;

  &:hover {
    border: 2px solid #0677b4;
    background-color: #fff;
    color: #0677b4;
    transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
  }

  &:active {
    color: #fff;
    background-color: #0677b4;
  }
  @media screen and (max-width: 1600px) {
    width: 60%;
    height: 40px;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 5vw;
    font-size: 2.5vw;
  }

  @media screen and (max-width: 600px) {
    height: 13vw;
    font-size: 5vw;

    &:hover {
      border: 0;
      background-color: #0677b4;
      color: #fff;
    }

    &:active {
      border: 2px solid #0677b4;
      background-color: #fff;
      color: #0677b4;
      transition: background-color 0.5s;
      -o-transition: background-color 0.5s;
      -moz-transition: background-color 0.5s;
      -webkit-transition: background-color 0.5s;
    }
  }
`;

export default FeedbackForm;
