import React, { useContext, useState } from "react";
import styled from "styled-components";
import PolicyContents from "./PolicyContents";
import { selectLanguage } from "assets/Common/ContextAPI";
import { POLICY } from "assets/Common/RenderData/Policy";

const Policy = ({ subscribePage, location }) => {
  subscribePage(location);
  const language = useContext(selectLanguage);
  const [nowShow, setShowPage] = useState("privacy");

  const { logo, privacyPolicy, termOfUse } = POLICY;
  const privacyPolicyInfos = privacyPolicy[language];
  const termOfUseInfos = termOfUse[language];

  const ShowPageConverter = (e) => {
    setShowPage(e.target.id);
  };

  return (
    <PolicyPageContainerDiv>
      <PolicyTitleContainerDiv>
        <PolicyImg src={logo} alt="아이엠랩로고" />
        <PolicyLinkWrapperUl language={language}>
          <PolicyLinkH2 id="privacy" language={language} nowShow={nowShow} onClick={ShowPageConverter}>
            {privacyPolicyInfos.name}
          </PolicyLinkH2>
          <PolicyLinkH2 id="term" language={language} nowShow={nowShow} onClick={ShowPageConverter}>
            {termOfUseInfos.name}
          </PolicyLinkH2>
        </PolicyLinkWrapperUl>
        <PartitionLineHr />
      </PolicyTitleContainerDiv>
      <PolicyContentsContainerDiv>
        <PolicyContents contents={nowShow === "privacy" ? privacyPolicyInfos.contents : termOfUseInfos.contents} />
      </PolicyContentsContainerDiv>
    </PolicyPageContainerDiv>
  );
};

const PolicyLinkWrapperUl = styled.ul`
  display: flex;
  justify-content: center;
  padding: 0;
  width: 100%;
  margin: auto;
  @media screen and (max-width: 600px) {
    flex-direction: ${(props) => (props.language === "KOR" ? "row" : "column")};
    margin: ${(props) => (props.language === "KOR" ? "auto" : "20px auto 20px auto")};
    width: 100%;
  }
`;

const PolicyLinkH2 = styled.h2`
  width: initial;
  margin: 50px 60px 50px 60px;
  cursor: pointer;
  color: ${(props) => (props.id === props.nowShow ? "rgb(0, 102, 204)" : "#848484")};
  :hover {
    color: rgb(0, 102, 204);
  }
  @media screen and (max-width: 600px) {
    margin: ${(props) => (props.language === "KOR" ? "50px 18px 50px 18px" : "10px")};
  }
`;

const PolicyPageContainerDiv = styled.div`
  position: "relative";
  margin-top: 150px;
  margin-bottom: 150px;
  @media screen and (max-width: 1024px) {
    margin-top: 10vw;
    margin-bottom: 10vw;
  }
`;

const PolicyTitleContainerDiv = styled.div`
  text-align: center;
  color: #848484;
  font-family: "NanumGothic";
  font-size: 13px;
`;

const PolicyImg = styled.img`
  @media screen and (max-width: 600px) {
    width: 200px;
  }
`;

const PartitionLineHr = styled.hr`
  width: 40%;
  border: 0;
  height: 1px;
  background: #ccc;
  margin-bottom: 50px;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
`;

const PolicyContentsContainerDiv = styled.div`
  font-family: "OpenSans";
  color: #848484;
  width: 70%;
  margin: auto;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
`;

export default Policy;
