import React from "react";
import styled from "styled-components";
import IntroContent from "./IntroContent";

const IntroContentList = ({ language, introPage }) => {
  const pageInfos = Object.keys(introPage);
  return (
    <IntroContentListContainerDiv>
      {pageInfos.map(pageInfo => {
        const page = introPage[pageInfo][language];
        return (
          <IntroContent
            key={page.name}
            language={language}
            title={page.name}
            image={page.image}
            url={page.url}
            description={page.description}
          />
        );
      })}
    </IntroContentListContainerDiv>
  );
};

const IntroContentListContainerDiv = styled.div`
  /*border: 3px solid blue;*/
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;

  font-family: NanumGothic;
  width: 100%;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-row-gap: 3vw;
    width: 100%;
    height: 100%;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: flex;
  }
`;

export default IntroContentList;
