import React from 'react';
import styled from 'styled-components';

const FootBottomLeft = () => {
  return (
    <FootBottomLeftWrapperDiv>
      {new Date().getFullYear()} © I.M.LAB Corporation
    </FootBottomLeftWrapperDiv>
  );
};

const FootBottomLeftWrapperDiv = styled.div`
  position: relative;
  top: 0.5vw;
  text-align: center;
  color: #848484;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    position: relative;
    right: 28vw;
    padding-bottom: 1vw;
  }

  @media screen and (max-width: 1024px) {
    font-size: 2.5vw;
    padding: 0.5vw;
    top: 0.1vw;
  }
  @media screen and (max-width: 700px) {
    font-size: 4vw;
    padding: 0.5vw;
    top: 0.1vw;
  }
`;
export default FootBottomLeft;
