import React, { useContext } from "react";
import styled from "styled-components";
import ServiceContentsList from "./ServiceContent/ServiceContentsList";
import { selectLanguage } from "assets/Common/ContextAPI";
import { PRODUCT_TITLE } from "assets/Common/RenderData/Services";

const Services = ({ subscribePage, location }) => {
  const language = useContext(selectLanguage);
  subscribePage(location);

  return (
    <div>
      <ServicePageContainerDiv>
        <ServiceTitleP>{PRODUCT_TITLE.title[language]}</ServiceTitleP>
        <ServiceContentsList language={language} />
      </ServicePageContainerDiv>
    </div>
  );
};

const ServicePageContainerDiv = styled.div`
  /*border: 3px solid blue;*/
  text-align: center;
  padding-bottom: 8vw;
  margin-top: 4vw;
  @media screen and (max-width: 1024px) {
    position: relative;
    top: 6vw;
  }
`;

const ServiceTitleP = styled.p`
  font-family: MontserratRegular;
  font-size: 2.5vw;
  color: #5d5d5d;
  @media screen and (max-width: 1024px) {
    font-size: 5vw;
    margin-bottom: 10vw;
  }
`;

export default Services;
