import React from "react";
import styled from "styled-components";
import { newLineMaker } from "assets/Common/Functions";

const RecruitMidContent = ({ language, name, top, mid, bottom }) => {
  return (
    <RecruitMidAllContainerDiv>
      <RecruitMidListSubjectP>{name}</RecruitMidListSubjectP>
      <RecruitMidListDescriptionContainerDiv>
        {[top, mid, bottom].map(position => (
          <React.Fragment key={position.name}>
            <RecruitMidListDescriptionTitleP>
              {position.name}
            </RecruitMidListDescriptionTitleP>
            <RecruitMidListDescriptionContentDiv>
              {newLineMaker(
                position.description,
                language === "KOR" ? "8px" : "10px"
              )}
            </RecruitMidListDescriptionContentDiv>
          </React.Fragment>
        ))}
      </RecruitMidListDescriptionContainerDiv>
      <RecruitMidContentDivideHr />
    </RecruitMidAllContainerDiv>
  );
};

//Styled CSS
const RecruitMidAllContainerDiv = styled.div`
  /*border: 1px solid red;*/
  font-family: MontserratRegular;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-column-gap: 3vw;
  grid-row-gap: 4vw;
  @media screen and (max-width: 1130px) {
    grid-template-columns: 100%;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-bottom: 5vw;
  }
`;

const RecruitMidListSubjectP = styled.p`
  /*border: 3px solid blue;*/
  padding-top: 0.5vw;
  margin: 0;
  font-size: 1.8vw;
  @media screen and (max-width: 1500px) {
    font-size: 2.3vw;
  }
  @media screen and (max-width: 1130px) {
    text-align: left;
    padding-right: 35vw;
    font-size: 3.2vw;
  }
  @media screen and (max-width: 700px) {
    font-size: 4.1vw;
  }
  @media screen and (max-width: 500px) {
    font-size: 6vw;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100vw;
  }
`;

const RecruitMidListDescriptionContainerDiv = styled.div`
  /*border: 3px solid blue;*/

  text-align: left;
  margin: 0;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    position: relative;
    left: 3vw;
    width: 200vw;
    margin-left: 0;
  }
`;

const RecruitMidListDescriptionTitleP = styled.p`
  font-weight: bold;
  font-size: 1vw;
  color: #5d5d5d;
  @media screen and (max-width: 1500px) {
    font-size: 1.8vw;
    margin-top: 0;
  }
  @media screen and (max-width: 1130px) {
    font-size: 2.4vw;
  }
  @media screen and (max-width: 700px) {
    font-size: 3vw;
  }
  @media screen and (max-width: 500px) {
    font-size: 5vw;
  }
`;

const RecruitMidListDescriptionContentDiv = styled.div`
  /*border: 3px solid yellow;*/
  position: relative;
  margin: 1.5vw auto 2vw auto;
  font-size: 0.9vw;
  font-weight: 350;
  color: #5d5d5d;
  bottom: 0.5vw;
  @media screen and (max-width: 1500px) {
    font-size: 1.3vw;
  }
  @media screen and (max-width: 1130px) {
    margin: 3vw auto 3vw auto;
    bottom: 2vw;
    font-size: 1.8vw;
  }
  @media screen and (max-width: 700px) {
    word-break: keep-all;
    font-weight: 400;
    font-size: 2.57vw;
  }
  @media screen and (max-width: 500px) {
    font-size: 3.7vw;
  }
`;

const RecruitMidContentDivideHr = styled.hr`
  position: relative;
  bottom: 3vw;
  width: 55vw;
  margin: 3vw auto 3vw auto;

  background: #ccc;
  @media screen and (max-width: 1500px) {
    width: 70vw;
  }
  @media screen and (max-width: 1130px) {
    width: 90vw;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: none;
  }
`;

export default RecruitMidContent;
