import React from 'react';
import styled from 'styled-components';
import MembersContentList from './MemberContentList';
import { ABOUT_MEMBERS_TITLE } from 'assets/Common/RenderData/About';

const Members = ({ language }) => {
  return (
    <MembersContainerDiv>
      <MemberTitleH1>{ABOUT_MEMBERS_TITLE.title[language]}</MemberTitleH1>
      <MemberDivideHr />
      <MembersContentList />
    </MembersContainerDiv>
  );
};

const MembersContainerDiv = styled.div`
  width: 80%;
  margin: auto;
`;

const MemberTitleH1 = styled.h1`
  font-family: MontserratRegular, sans-serif;
  font-weight: 700;
  font-size: 40px;
  @media screen and (max-width: 600px) {
    font-size: 7.5vw;
  }
`;

const MemberDivideHr = styled.hr`
  width: 30%;
  margin: 2vw auto 5vw auto;
  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;

export default Members;
