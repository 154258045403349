import React, { useContext } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { DotLessUl } from "assets/Common/CSS";
import { selectLanguage, pageInformations } from "assets/Common/ContextAPI";
import HeadNavLanguageSelect from "./HeadNavLanguageSelect";

const HeadMenu = ({ subscribeLanguage, visibleStatus }) => {
  const PAGE_INFO = useContext(pageInformations);
  const language = useContext(selectLanguage);
  const menus = Object.values(PAGE_INFO).slice(0, -2);

  return (
    <HeadMenuContainerDiv>
      <DotLessUl>
        {menus.map(menu => {
          const snnipet = menu[language];
          return (
            <HeadMenuWrapperLi key={menu.name} visibleStatus={visibleStatus}>
              <HeadMenuContentNavLink
                exact
                activeStyle={{ color: "#0066cc" }}
                to={snnipet.url}
              >
                {menu.name.replace("-", " ").toUpperCase()}
              </HeadMenuContentNavLink>
            </HeadMenuWrapperLi>
          );
        })}
      </DotLessUl>
      <HeadNavLanguageSelect
        subscribeLanguage={subscribeLanguage}
        visibleStatus={visibleStatus}
      />
    </HeadMenuContainerDiv>
  );
};

//styled CSS
const HeadMenuContainerDiv = styled.div`
  position: relative;
  left: 5vw;
  margin-bottom: 1rem;

  @media screen and (max-width: 1600px) {
    left: 1vw;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    position: relative;
    left: 30vw;
  }
`;

const HeadMenuWrapperLi = styled.li`
  margin: 1.5vw;
  float: left;
`;

//Styled CSS
const HeadMenuContentNavLink = styled(NavLink)`
  position: relative;
  font-family: MontserratRegular;
  color: black;
  text-decoration: none;
  margin: 0.8rem;
  font-size: 1.1em;
  padding: 0;
  margin: 0;

  &:hover {
    text-decoration: none;
    color: #0066cc;
    font-size: 1.1em;
  }

  &:visited {
    text-decoration: none;
  }
`;

export default HeadMenu;
