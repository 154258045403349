import React from 'react';
import styled from 'styled-components';
import { PARTNERS_CONTENTS } from 'assets/Common/RenderData/Home';

const PartnerContentList = () => {
  let partnerImgs = Object.keys(PARTNERS_CONTENTS);
  return (
    <PartnersListContainerDiv>
      <PartnersListWrapperDiv>
        {partnerImgs.slice(0, 6).map((partnerImg) => (
          <PartnersImg
            key={partnerImg}
            src={PARTNERS_CONTENTS[partnerImg].image}
            alt={PARTNERS_CONTENTS[partnerImg].name}
          />
        ))}
      </PartnersListWrapperDiv>
      <DividerHr />
      <PartnersListWrapperDiv>
        {partnerImgs.slice(6).map((partnerImg) => (
          <PartnersImg
            key={partnerImg}
            src={PARTNERS_CONTENTS[partnerImg].image}
            alt={PARTNERS_CONTENTS[partnerImg].name}
          />
        ))}
      </PartnersListWrapperDiv>
    </PartnersListContainerDiv>
  );
};

const PartnersListContainerDiv = styled.div`
  width: 80%;
  margin: auto;
`;

const PartnersListWrapperDiv = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    margin: auto;
    width: 90%;
  }
`;

const DividerHr = styled.hr`
  width: 40vw;
  border: 0;
  height: 1px;
  margin: 4vw auto 1vw auto;
  background: #ccc;
  @media screen and (max-width: 1024px) {
    width: 80vw;
  }
`;

const PartnersImg = styled.img`
  margin: 15px 100px 15px 100px;
  width: 200px;
  height: 150px;
  @media screen and (max-width: 600px) {
    width: 130px;
    height: 100px;
  }
`;

export default PartnerContentList;
