import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const FootTopRight = ({ data }) => {
  return (
    <FootTopRightWrapperDiv>
      <FootTopRightContentNavLink to={data.url}>
        {data.name}
      </FootTopRightContentNavLink>
    </FootTopRightWrapperDiv>
  );
};

//Styled CSS
const FootTopRightWrapperDiv = styled.div`
  text-align: center;
  @media screen and (max-width: 1024px) {
    /*border: 1px solid red;*/
    width: 100%;
    height: 100%;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    position: relative;
    left: 60vw;
  }
`;

const FootTopRightContentNavLink = styled(NavLink)`
  position: relative;
  right: 1.1vw;
  color: #848484;
  text-decoration: none;

  &:hover {
    color: #0066cc;
  }

  &:visited {
    text-decoration: none;
  }

  @media screen and (max-width: 1024px) {
    right: 0;
    top: 0.5vw;
  }
`;

export default FootTopRight;
