import React from "react";
import styled from "styled-components";
import { newBrowserOpener } from "assets/Common/Functions";

const ServiceContentRight = ({ logo, url, image, description, language }) => {
  return (
    <>
      <RightProductImg src={image} alt="제품이미지" />
      <RightProductLogoImg src={logo} alt="제품로고" />
      <DescriptionContainerDiv>
        <RightProductDescriptionP>{description}</RightProductDescriptionP>
        {url ? (
          <SelectableP onClick={newBrowserOpener.bind(null, url)}>
            {language === "KOR" ? "더 보기" : "MORE"} >
          </SelectableP>
        ) : null}
      </DescriptionContainerDiv>
    </>
  );
};

const RightProductDescriptionP = styled.p`
  word-break: keep-all;
  @media screen and (max-width: 1600px) {
    line-height: 2vw;
    font-size: 1.6vw;
  }
  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 600px) {
    font-size: 3.8vw;
    word-break: break-all;
    text-align: justify;
    line-height: 6vw;
  }
`;

const RightProductLogoImg = styled.img`
  position: absolute;
  width: 14vw;
  top: 18%;
  left: 2%;
  @media screen and (max-width: 1024px) {
    /*border: 3px solid red;*/
  }
  @media screen and (max-width: 600px) {
    /*border: 3px solid red;*/
    position: static;
    width: 45vw;
    margin-top: 10px;
  }
`;

const RightProductImg = styled.img`
  position: absolute;
  top: 10%;
  right: 0;
  width: 50%;
  @media screen and (max-width: 1024px) {
    /*border: 3px solid red;*/
  }
  @media screen and (max-width: 600px) {
    /*border: 3px solid red;*/
    position: static;
    width: 100%;
    right: 0vw;
    top: 0;
  }
`;

const DescriptionContainerDiv = styled.div`
  position: absolute;
  top: 30%;
  left: 2%;
  text-align: left;
  width: 45%;
  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 600px) {
    position: static;
    width: 100%;
  }
`;

const SelectableP = styled.p`
  cursor: pointer;
  margin-top: 3vw;
  padding-left: 0.5vw;
  text-align: right;
  font-family: NanumGothic, sans-serif;
  font-size: 0.9vw;
  color: #0066cc;
  @media screen and (max-width: 1600px) {
    font-size: 1.5vw;
  }
  @media screen and (max-width: 600px) {
    font-size: 3.4vw;
  }
`;
export default ServiceContentRight;
