export const PRODUCT_TITLE = {
  title: {
    KOR: "PRODUCT",
    ENG: "PRODUCT"
  }
};
export const PRODUCT_LIST = {
  1: {
    KOR: {
      name: "cpr add-on kit",
      description:
        "CPR Add-on kit(애드온키트)는 하티센스 제품의 가장 본질적인 기능과 노하우만을 담아 탄생한 제품입니다. 레어달, 나스코, 프레스탄, 엠부 등과 같이 널리 사용되는 마네킹에 최적화된 키트로, 마네킹 내부에 손쉽게 설치할 수 있습니다. 앱을 통해 압박(깊이, 속도, 이완 여부)과 호흡(양, 속도)에 대한 실시간 피드백을 받아볼 수 있으며, 평가, LMS 등의 기능을 확장하여 사용이 가능합니다. CPR add on kit는 개별 교육, 소규모 교육, 대규모 단체 교육(classroom) 등의 다양한 교육 형태에서 유연하게 활용할 수 있습니다.",
      image:
        "https://imlab-contents.s3-ap-northeast-1.amazonaws.com/imlabworld/service/imlabworld_service_aok.png",
      url: "https://www.cpraddonkit.com",
      logo:
        "https://imlab-contents.s3-ap-northeast-1.amazonaws.com/imlabworld/service/aokpbh.png",
      position: "left"
    },
    ENG: {
      name: "cpr add-on kit",
      description:
        "CPR Add-on Kit is the optimized kit to be installed inside the manikins of most widely used brands. It is connected to apps to provide accurate real-time feedback of compression metrics(depth, rate, recoil) and breath metrics(amount, rate). CPR Add-on Kit is suited to be used for various training environments such as individual training, small group training, large group training(classroom) with flexibility and convenience.",
      image:
        "https://imlab-contents.s3-ap-northeast-1.amazonaws.com/imlabworld/service/imlabworld_service_aok.png",
      url: "https://en.cpraddonkit.com",
      logo:
        "https://imlab-contents.s3-ap-northeast-1.amazonaws.com/imlabworld/service/aokpbh.png",
      position: "left"
    }
  },
  2: {
    KOR: {
      name: "heartisense",
      description:
        "하티센스는 기존 마네킹 안에 설치하는 IoT 제품으로, 센서키트와 앱으로 구성되어 있습니다. 기존의 마네킹을 보다 스마트하게 만들어 주어 새로운 심폐소생술 트레이닝을 경험할 수 있도록 합니다. 따라서 정확하고 효과적인 교육과 평가를 가능하게 하며, 교육의 몰입도를 높일 뿐 아니라 교육생 관리도 편리하게 할 수 있습니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/services%2Fhs%2Fservice_heartisense-1-2.jpg?alt=media&token=9d46ee71-0b7f-43b1-b9b5-534b4d2dbfa1",
      url: "https://www.heartisense.com/cpr_heartisense/overview",
      logo:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/services%2Fhs%2Fheartisense_logo.png?alt=media&token=a76c4813-9b05-4699-bfa7-c07c0603e9cf",
      position: "right"
    },
    ENG: {
      name: "heartisense",
      description:
        "HeartiSense is an IoT product installed in the existing mannequin, consisting of a sensor kit and an app. Make your existing mannequins smarter so you can experience new CPR training. This enables accurate and effective training and evaluation, as well as immersive training and facilitates student management. ",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/services%2Fhs%2Fservice_heartisense-1-2.jpg?alt=media&token=9d46ee71-0b7f-43b1-b9b5-534b4d2dbfa1",
      url: "https://www.heartisense.com/eng/cpr_heartisense/overview",
      logo:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/services%2Fhs%2Fheartisense_logo.png?alt=media&token=a76c4813-9b05-4699-bfa7-c07c0603e9cf",
      position: "right"
    }
  },
  3: {
    KOR: {
      name: "heartisense exiting",
      description:
        "하티센스 익사이팅은 하티센스 제품에 증강현실(Augmented Reality) 기술을 활용한 프로젝션 기반 시뮬레이터입니다. 사용자가 실제 상황에서 교육을 받는 것 같은 익사이팅한 경험을 제공합니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/services%2Fhs_ex%2Fexciting.jpg?alt=media&token=360724e5-1b57-4987-bc46-7811ebc41092",
      url: "https://www.heartisense.com/cpr_application/exciting",
      logo:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/services%2Fhs_ex%2Fexciting_logo.png?alt=media&token=05d307c4-7965-4066-824c-24d93a6d3c39",
      position: "left"
    },
    ENG: {
      name: "heartisense exiting",
      description:
        "HeartiSense Exciting is a projection-based simulator that uses Augmented Reality technology in HeartiSense products. It delivers an exciting experience that makes users feel like they're being trained in real-world situations.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/services%2Fhs_ex%2Fexciting.jpg?alt=media&token=360724e5-1b57-4987-bc46-7811ebc41092",
      url: "https://www.heartisense.com/eng/cpr_application/exciting",
      logo:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/services%2Fhs_ex%2Fexciting_logo.png?alt=media&token=05d307c4-7965-4066-824c-24d93a6d3c39",
      position: "left"
    }
  },
  4: {
    KOR: {
      name: "cprcube",
      description:
        "온 가족의 위한 보급형 심폐소생술 트레이너로 제작한 cprCUBE는 소리, 빛, 진동 등의 다양한 피드백을 지원하는 큐브 모양의 교구와 이와 연동되는 어플리케이션으로 구성되어 있습니다. 큐브 및 어플리케이션을 통해 사용자에게 실시간 피드백을 제공하여, 보다 효과적이고 몰입도 높은 교육을 가능하게 합니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/services%2Fcube%2Fcprcube%20service%20banner_x2.jpg?alt=media&token=7c915ceb-6519-48f1-b6ef-84bc7bcf674b",
      url: "https://www.cprcube.com/",
      logo:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/services%2Fcube%2FcprCUBE.png?alt=media&token=537c6c99-fc2a-4994-85db-2f1bbe49d773",
      position: "right"
    },
    ENG: {
      name: "cprcube",
      description:
        "Designed as a low-cost CPR trainer for the whole family, cprCUBE consists of a cube-shaped teaching aid that supports a variety of feedbacks such as sound, light and vibration, along with applications associated with it. Cubes and applications provide real-time feedback to users, enabling more effective and immersive training.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/services%2Fcube%2Fcprcube%20service%20banner_x2.jpg?alt=media&token=7c915ceb-6519-48f1-b6ef-84bc7bcf674b",
      url: "https://www.cprcube.com/",
      logo:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/services%2Fcube%2FcprCUBE.png?alt=media&token=537c6c99-fc2a-4994-85db-2f1bbe49d773",
      position: "right"
    }
  },
  5: {
    KOR: {
      name: "hertisense immersion",
      description:
        "하티센스 이머전은 증강가상(Augmented Virtuality) 기술을 이용한 몰입형 시뮬레이터입니다. 사용자는 타블렛 PC대신 오큘러스와 같은 VR헤드셋을 쓰고 가상공간에서 몰입감 있는 심폐소생술 교육을 받을 수 있습니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/services%2Fhs_im%2Fimmersion-1.png?alt=media&token=d4598ca6-5a37-47c2-b7b9-ffb5ef96cfd5",
      url: null,
      logo:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/services%2Fhs_im%2Fimmersion.png?alt=media&token=4d377c0f-4cba-4410-8ebd-f388e9c24978",
      position: "left"
    },
    ENG: {
      name: "hertisense immersion",
      description:
        "HeartiSense Immersion is an immersive simulator using Augmented Virtuality technology. Users can wear VR headsets like Oculus instead of tablet PCs and get immersive CPR training in virtual space.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/services%2Fhs_im%2Fimmersion-1.png?alt=media&token=d4598ca6-5a37-47c2-b7b9-ffb5ef96cfd5",
      url: null,
      logo:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/services%2Fhs_im%2Fimmersion.png?alt=media&token=4d377c0f-4cba-4410-8ebd-f388e9c24978",
      position: "left"
    }
  }
};
