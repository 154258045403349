/*
재사용성을 높이기위해 공통으로 사용되는 state들을 보관하는 파일입니다. 
이 곳에서의 변경사항은 모든 컴포넌트에 자동으로 적용됩니다.
*/

/* 
각 메뉴 탭에 대한 간단한 정보가 담겨있습니다.
메뉴 선택 시 url, 언어에 따른 메뉴표시 상태, 메뉴에 따른 배너 메시지가 이곳에 포함됩니다.  
*/

export const PAGE_INFO = {
  a: {
    name: "home",
    KOR: {
      name: "홈",
      url: "/company",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/page_header%2Fhome-page.jpg?alt=media&token=a3aa3b50-b209-42a0-85af-b0b6e1bd8237",
      description: "BE A LIFE SAVER",
    },
    ENG: {
      name: "home",
      url: "/company",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/page_header%2Fhome-page.jpg?alt=media&token=a3aa3b50-b209-42a0-85af-b0b6e1bd8237",
      description: "BE A LIFE SAVER",
    },
  },
  b: {
    name: "about-us",
    KOR: {
      name: "회사 소개",
      url: "/company/about-us",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/page_header%2Fabout-page.jpg?alt=media&token=c249f5aa-f557-4d06-8229-c44f2d9037bc",
      description: "ABOUT I.M.LAB",
    },
    ENG: {
      name: "about-us",
      url: "/company/about-us",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/page_header%2Fabout-page.jpg?alt=media&token=c249f5aa-f557-4d06-8229-c44f2d9037bc",
      description: "ABOUT I.M.LAB",
    },
  },
  c: {
    name: "services",
    KOR: {
      name: "서비스",
      url: "/company/services",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/page_header%2Fhome-page.jpg?alt=media&token=a3aa3b50-b209-42a0-85af-b0b6e1bd8237",
      description: "SERVICES",
    },
    ENG: {
      name: "services",
      url: "/company/services",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/page_header%2Fhome-page.jpg?alt=media&token=a3aa3b50-b209-42a0-85af-b0b6e1bd8237",
      description: "SERVICES",
    },
  },
  d: {
    name: "news",
    KOR: {
      name: "언론보도",
      url: "/company/news",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/page_header%2Fnews-page.jpg?alt=media&token=c9078f07-c612-40a2-bbde-ecb33d14940f",
      description: "NEWS",
    },
    ENG: {
      name: "news",
      url: "/company/news",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/page_header%2Fnews-page.jpg?alt=media&token=c9078f07-c612-40a2-bbde-ecb33d14940f",
      description: "NEWS",
    },
  },
  e: {
    name: "recruit",
    KOR: {
      name: "채용",
      url: "/company/recruit",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/page_header%2Frecruit-page.jpg?alt=media&token=b85c66b0-cfe4-488a-b0c4-d9bf28709659",
      description: "RECRUIT",
    },
    ENG: {
      name: "recruit",
      url: "/company/recruit",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/page_header%2Frecruit-page.jpg?alt=media&token=b85c66b0-cfe4-488a-b0c4-d9bf28709659",
      description: "RECRUIT",
    },
  },
  f: {
    name: "contact-us",
    KOR: {
      name: "문의",
      url: "/company/contact-us",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/page_header%2Fcontact-page.jpg?alt=media&token=e51584a9-f7d2-467b-a2de-33c059fcce32",
      description: "CONTACT US",
    },
    ENG: {
      name: "contact-us",
      url: "/company/contact-us",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/page_header%2Fcontact-page.jpg?alt=media&token=e51584a9-f7d2-467b-a2de-33c059fcce32",
      description: "CONTACT US",
    },
  },
  g: {
    name: "privacy-policy",
    KOR: {
      name: "개인정보 취급방침 / 이용약관",
      url: "/company/privacy-policy",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/page_header%2Fservices-page.jpg?alt=media&token=8427ea81-0ab6-4c1a-9a50-a775b57f64d9",
      description: "PRIVACY POLICY",
    },
    ENG: {
      name: "privacy-policy / term of service",
      url: "/company/privacy-policy",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/page_header%2Fservices-page.jpg?alt=media&token=8427ea81-0ab6-4c1a-9a50-a775b57f64d9",
      description: "PRIVACY POLICY",
    },
  },
  h: {
    name: "not-found",
    KOR: {
      description: "페이지를 찾을 수 없습니다.",
    },
    ENG: {
      description: "UNKOWN PAGE",
    },
  },
  i: {
    name: "landing",
    KOR: {
      name: "랜딩",
      url: "/remote"
    },
    ENG: {
      name: "landing",
      url: "/remote"
    },
  },
};

/* 
아이엡랩의 서비스 페이지 정보입니다.
데이터는 제품명에 대해 언어에 따른 명칭과, 회사 사이트가 저장 되어있습니다. 
*/
export const MAIN_PRODUCT_LIST = {
  a: {
    KOR: {
      name: "CPR Add-on Kit",
      url: "https://www.cpraddonkit.com/",
      description: "CPR 트레이닝에 무한한 가능성 더하기",
      logo:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/main%2FAOK_LOGO.png?alt=media&token=8afab0b4-1336-4f8c-b709-7f835373739c",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/main%2FAOK_IMG.jpg?alt=media&token=0b0e5eec-e0d6-4a51-8416-aa962b031272",
    },
    ENG: {
      name: "CPR Add-on Kit",
      url: "https://en.cpraddonkit.com/",
      description: "Add limitless possibilities on your CPR training",
      logo:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/main%2FAOK_LOGO.png?alt=media&token=8afab0b4-1336-4f8c-b709-7f835373739c",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/main%2FAOK_IMG.jpg?alt=media&token=0b0e5eec-e0d6-4a51-8416-aa962b031272",
    },
  },
  b: {
    KOR: {
      name: "cprCUBE",
      url: "https://www.cprcube.com/",
      description: "세상에서 제일 작은 CPR교실",
      logo:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/main%2FCUBE_LOGO.png?alt=media&token=ded7916f-be5e-4c65-805e-884e54ea25d2",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/main%2FCUBE_IMG.jpg?alt=media&token=795d460e-800f-4f51-95cb-ba5aae38017c",
    },
    ENG: {
      name: "cprCUBE",
      url: "https://www.cprcube.com/",
      description: "The smallest CPR class ever",
      logo:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/main%2FCUBE_LOGO.png?alt=media&token=ded7916f-be5e-4c65-805e-884e54ea25d2",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/main%2FCUBE_IMG.jpg?alt=media&token=795d460e-800f-4f51-95cb-ba5aae38017c",
    },
  },
  c: {
    KOR: {
      name: "하티센스",
      url: "https://www.heartisense.com/cpr_heartisense/overview",
      description: "심폐소생술 교육과 학습을 위한 최적의 솔루션",
      logo:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/main%2FHS_LOGO.png?alt=media&token=d7e6c7de-a810-42b5-a99c-79d30189ce97",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/main%2FHS_IMG.jpg?alt=media&token=67ffc343-b0c8-41a7-a8ee-7f995d952468",
    },
    ENG: {
      name: "heartiSense",
      url: "https://www.heartisense.com/eng/cpr_heartisense/overview",
      description: "The best and the most effective CPR training solution",
      logo:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/main%2FHS_LOGO.png?alt=media&token=d7e6c7de-a810-42b5-a99c-79d30189ce97",
      image:
        "https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/main%2FHS_IMG.jpg?alt=media&token=67ffc343-b0c8-41a7-a8ee-7f995d952468",
    },
  },
};
