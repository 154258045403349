import React, { useContext } from "react";
import { selectLanguage } from "assets/Common/ContextAPI";
import Intro from "./Intro/Intro";
import Partners from "./Partners/Partners";

const Home = ({ subscribePage, location }) => {
  subscribePage(location);
  const language = useContext(selectLanguage);

  return (
    <>
      <Intro language={language} />
      <Partners language={language} />
    </>
  );
};

export default Home;
