import React from "react";
import styled from "styled-components";

const HeaderMsg = ({ menuInfo }) => {
  return (
    <>
      <HeaderMsgContentP menuInfo={menuInfo.name}>
        {menuInfo.description}
      </HeaderMsgContentP>
    </>
  );
};

// Styled CSS
const HeaderMsgContentP = styled.p`
  position: absolute;
  z-index: 10;
  font-family: MontserratBold;
  font-weight: 400;
  font-size: ${props =>
    props.menuInfo === "홈" || props.menuInfo === "home" ? "4vw" : "4vw"};

  top: ${props =>
    props.menuInfo === "홈" || props.menuInfo === "home" ? "6vw" : "6vw"};
  width: 100%;

  color: white;

  text-align: center;
  text-shadow: ${props =>
    props.menuInfo === "홈" || props.menuInfo === "home"
      ? "3px 3px 3px gray"
      : null};

  @media screen and (max-width: 1024px) {
    font-size: 8vw;
    top: 0;
  }
`;

export default HeaderMsg;
