import React from "react";
import styled from "styled-components";
import { MAIN_PRODUCT_LIST } from "assets/Common/RenderData/Main";
import { newBrowserOpener } from "assets/Common/Functions";
import Product from "./Product";

const ProductList = ({ language }) => {
  const sites = Object.keys(MAIN_PRODUCT_LIST);
  return (
    <>
      {sites ? (
        <ProductContentsWrapperUl>
          {sites.map(site => {
            const snnipet = MAIN_PRODUCT_LIST[site][language];
            return (
              <ProductContentsLi
                key={site}
                onClick={newBrowserOpener.bind(null, snnipet.url)}
              >
                <Product
                  name={snnipet.name}
                  logo={snnipet.logo}
                  image={snnipet.image}
                  description={snnipet.description}
                />
              </ProductContentsLi>
            );
          })}
        </ProductContentsWrapperUl>
      ) : null}
    </>
  );
};

//Styled CSS
const ProductContentsWrapperUl = styled.ul`
  list-style: none;
  padding: 0;
  width: 68%;
  margin: auto;
  @media screen and (max-width: 1600px) {
    width: 90%;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 600px) {
  }
`;

const ProductContentsLi = styled.li`
  /*border: 1px solid black;*/
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 15vw;
  &:hover {
    transform: scale(1.05);
    -o-transfom: scale(1.05);
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);

    transition: transform 1s;
    -o-transition: transform 1s;
    -moz-transition: transform 1s;
    -webkit-transition: transform 1s;

    box-shadow: 1px 1px 20px #ddd;
    z-index: 300;
  }

  @media screen and (max-width: 1600px) {
    height: 22vw;
  }
  @media screen and (max-width: 1024px) {
    height: 28vw;
    &:hover {
      transform: none;
      transition: none;
      box-shadow: none;
      z-index: 0;
    }
  }
  @media screen and (max-width: 600px) {
    height: 40vw;
  }
`;
export default ProductList;
