import React, { useContext } from "react";
import styled from "styled-components";
import { selectLanguage, pageInformations } from "assets/Common/ContextAPI";

const NoMatchPage = ({ subscribePage, location }) => {
  subscribePage(location);
  const language = useContext(selectLanguage);
  const PAGE_INFO = useContext(pageInformations);
  const NotFoundPageContent = Object.values(PAGE_INFO)[7][language].description;

  return (
    <NoMatchPageContainerDiv>
      <NoMatchPageTitleP>404 PAGE NOT FOUND</NoMatchPageTitleP>
      <NoMatchPageDivideHr />
      <NoMatchPageContentP>{NotFoundPageContent}</NoMatchPageContentP>
    </NoMatchPageContainerDiv>
  );
};

const NoMatchPageContainerDiv = styled.div`
  margin: 10vw;
  text-align: center;
`;

const NoMatchPageTitleP = styled.p`
  font-weight: bold;
  font-size: 3.1vw;
  font-family: OpenSans;

  @media screen and (max-width: 1024px) {
    font-size: 7vw;
  }
`;

const NoMatchPageDivideHr = styled.hr`
  width: 50vw;
  border: 0;
  height: 1px;
  background: #ccc;
  @media screen and (max-width: 1024px) {
    width: 80vw;
  }
`;

const NoMatchPageContentP = styled.p`
  font-family: OpenSans;
  font-size: 2.2vw;
  color: #979797;
  @media screen and (max-width: 1024px) {
    font-size: 6vw;
  }
`;

export default NoMatchPage;
