/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import HeadLogo from "./HeadNavLogo";
import HeadMenu from "./HeadNavMenu";

const HeadNav = ({
  subscribeLanguage,
  subscribeMenuStatus,
  menuVisibleStatus
}) => {
  return (
    <HeadNavContainerDiv>
      <div />
      <HeadLogo subscribeMenuStatus={subscribeMenuStatus} />
      <HeadMenu subscribeLanguage={subscribeLanguage} />
      <HeadNavMobileMenuImg
        src="https://firebasestorage.googleapis.com/v0/b/imlab-web-homepage.appspot.com/o/menu-icon.png?alt=media&token=f235dd5e-7e73-41b9-9bb5-4c949f2e4bbd"
        alt="mobile-icon"
        onClick={() => {
          subscribeMenuStatus(!menuVisibleStatus);
        }}
      />
    </HeadNavContainerDiv>
  );
};

//Styled CSS
const HeadNavContainerDiv = styled.div`
  margin-top: 1em;
  display: -ms-grid;
  display: grid;
  grid-template-columns: 0.8fr auto 78%;
`;

const HeadNavMobileMenuImg = styled.img`
  display: none;
  @media screen and (max-width: 1024px) {
    position: absolute;
    display: block;
    top: 34px;
    right: 6vw;
  }
`;

export default HeadNav;
