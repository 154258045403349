import React, { useContext } from "react";
import styled from "styled-components";
import HeaderImg from "./HeaderImg";
import HeaderMsg from "./HeaderMsg";
import {
  selectLanguage,
  selectPage,
  pageInformations
} from "assets/Common/ContextAPI";

const Header = () => {
  const PAGE_INFO = useContext(pageInformations);
  const page = useContext(selectPage);
  const language = useContext(selectLanguage);
  const data = Object.values(PAGE_INFO).filter(curr => curr.name === page)[0];

  return (
    <HeaderContainerDiv menuInfo={data.name}>
      <HeaderImg page={page} menuInfo={data[language]} />
      <HeaderMsg menuInfo={data[language]} />
    </HeaderContainerDiv>
  );
};

const HeaderContainerDiv = styled.div`
  /*border: 3px solid red;*/
  position: relative;
  margin-top: 10px;
  z-index: 20;
  max-width: 100%;
  background-color: gray;
  height: ${props =>
    props.menuInfo === "home" || props.menuInfo === "홈" ? "26vw" : "26vw"};
  overflow: ${props =>
    props.menuInfo === "home" || props.menuInfo === "홈"
      ? "visible"
      : "hidden"};
  @media screen and (max-width: 1024px) {
    margin-top: 100px;
    height: 26vw;
    overflow: visible;
  }
  @media screen and (max-width: 600px) {
    margin-top: 80px;
    height: 26vw;
    overflow: visible;
  }
`;

export default Header;
