import React, { useContext } from "react";
import styled from "styled-components";
import { selectLanguage, pageInformations } from "assets/Common/ContextAPI";

const RedirectPage = ({ subscribePage, location }) => {
  subscribePage(location);
  const language = useContext(selectLanguage);
  const PAGE_INFO = useContext(pageInformations);
  const NotFoundPageContent = Object.values(PAGE_INFO)[7][language].description;

  return (
    <RedirectPageContainerDiv>
      <img src="/redirect.png" alt="redirect image"/>
      <RedirectPageDivideHr />
      <RedirectPageContentP>연결되지 않는다면, 다음 링크로 이동하세요 : https://www.notion.so/I-M-LAB-8c54be12c6ab474e85344f75843b66df</RedirectPageContentP>
    </RedirectPageContainerDiv>
  );
};

const RedirectPageContainerDiv = styled.div`
  margin: 10vw;
  text-align: center;
  img {
    width: 100%;
    max-width: 700px;
  }
`;

const RedirectPageTitleP = styled.p`
  font-weight: bold;
  font-size: 3.1vw;
  font-family: OpenSans;

  @media screen and (max-width: 1024px) {
    font-size: 7vw;
  }
`;

const RedirectPageDivideHr = styled.hr`
  width: 50vw;
  border: 0;
  height: 1px;
  background: #ccc;
  @media screen and (max-width: 1024px) {
    width: 80vw;
  }
`;

const RedirectPageContentP = styled.p`
  font-family: OpenSans;
  font-size: 1.2vw;
  color: #979797;
  @media screen and (max-width: 1024px) {
    font-size: 6vw;
  }
`;

export default RedirectPage;
